/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Component, Input} from '@angular/core';
/*
 *  @author     @version    @date           @description
 *  EElangav    01          Feb 14, 2024    AFLL-20711 ANZ | Localize Privacy Policy copy  for a HCP user 
 */
@Component({
    selector: 'app-anz-privacy-policy',
    templateUrl: './privacy-policy-anz.component.html',
    styleUrls: ['./privacy-policy-anz.component.scss', '../footer-links-common.scss']
})

export class ANZPrivacyPolicyComponent {
    @Input() public isUsedInAcceptance = false;
}
