<app-loading-spinner></app-loading-spinner>
<app-header></app-header>

<div class="dashboard-main-container">
    <div class="dashboard-card-container">
        <mat-card class="app-image-cards" *ngFor="let app of apps">
            <img class="app-images" src="../../assets/img/{{app.image}}" alt="{{app.title}}" />
            <br /> <br />
            <div class="description">
                <h2 class="text-center">{{app.title}}</h2>
            </div>
            <br />
            <div class="launch-button">
                <button mat-flat-button (click)="navigateTo(app)" class="launch-btn"
                    disableRipple>{{app.launchButtonText}}</button>
            </div>
        </mat-card>
    </div>
</div>

<app-footer [isAuthenticated]="true" class="tkr-footer-set"></app-footer>