import { Injectable } from '@angular/core';
import {UserModel} from '../model/user.model';

/*
 * @author          @version    @date           @description
 * LMudalig         01          Dec 15, 2023    AFLL-19610 Sonar | VDS Portal | Removed unexpected empty constructor.
 * HSenevir         02          Jan 26, 2024    AFLL-20257 - Enabled Okta session logout
 */

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  sessionKeys = {
    profile: 'userSession',
    userEmail: 'userEmail',
    isLoggedIn: 'isLoggedIn',
    isFromDSMobileApp: 'isFromDSMobileApp',
    authUrl: 'authUrl',
    logoutUrl: 'logoutUrl'
  };

  getLogoutURL(): string {
    return localStorage.getItem(this.sessionKeys.logoutUrl);
  }

  setLogoutURL(value: string): void {
    localStorage.setItem(this.sessionKeys.logoutUrl, value);
  }

  clearLogoutUrl(): void {
    localStorage.removeItem(this.sessionKeys.logoutUrl);
  }

  setSessionValue(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  getSessionValue(key: string): string {
    return sessionStorage.getItem(key);
  }

  clearSessions(): void {
    sessionStorage.clear();
  }

  clearUserSession(): void {
    sessionStorage.removeItem(this.sessionKeys.profile);
  }

  setUserSession(userSession: UserModel): void {
    this.setSessionValue(this.sessionKeys.profile, JSON.stringify(userSession));
    this.setUserEmail(userSession.email);
  }

  getUserSession(): UserModel {
    const userSession = sessionStorage.getItem(this.sessionKeys.profile);
    return JSON.parse(userSession);
  }

  setUserEmail(userEmail: string): void {
    this.setSessionValue(this.sessionKeys.userEmail, userEmail);
  }

  getUserEmail(): string {
    return sessionStorage.getItem(this.sessionKeys.userEmail);
  }

  isLoggedIn(): boolean {
    return localStorage.getItem(this.sessionKeys.isLoggedIn) ?
      JSON.parse(localStorage.getItem(this.sessionKeys.isLoggedIn)) : false;
  }

  // leveraging localStorage to handle multiple tabs
  setLoggedIn(isLoggedIn: boolean): void {
    localStorage.setItem(this.sessionKeys.isLoggedIn, JSON.stringify(isLoggedIn));
  }
}
