/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterLinksComponent } from './footer-links.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FooterLinksRoutingModule } from './footer-links-routing.module';
import { SharedModule } from '../shared/shared.module';

// Angular Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CookiePoliciesComponent } from './cookie-policies/cookie-policies.component';
import { UKPrivacyPolicyComponent } from './privacy-policy-uk/privacy-policy-uk.component';
import { DepuySynthesEmployeePrivacyPolicyComponent } from './depuy-synthes-employee-privacy-policy/depuy-synthes-employee-privacy-policy.component';
import { ANZPrivacyPolicyComponent } from './privacy-policy-anz/privacy-policy-anz.component';
/*
 *  @author     @version    @date           @description
 *  EElangav    01          Feb 14, 2024    AFLL-20711 ANZ | Localize Privacy Policy copy  for a HCP user
 *  PPareek     02          Jul 25, 2024    AFLL-21763 - Upgrade Angular to v18 and Material to V18 (M3)
 */
@NgModule({
    exports: [
        FooterLinksComponent,
        PrivacyPolicyComponent,
        UKPrivacyPolicyComponent,
        ANZPrivacyPolicyComponent
    ],
    declarations: [
        FooterLinksComponent,
        ContactUsComponent,
        PrivacyPolicyComponent,
        CookiePoliciesComponent,
        UKPrivacyPolicyComponent,
        DepuySynthesEmployeePrivacyPolicyComponent,
        ANZPrivacyPolicyComponent
    ],
    bootstrap: [
        FooterLinksComponent
    ],
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatInputModule,
        MatFormFieldModule,
        FooterLinksRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class FooterLinksModule { }
