import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ValidatorService } from '../../service/validator.service';
import { ExpiredLinkModalComponent } from './expired-link-modal/expired-link-modal.component';
import { AuthService } from '../../service/auth.service';

/*
 * @author          @version    @date           @description
 * JVeerasa         01          2023-03-01      AFLL-16881 - VDS Portal | Upgrade Angular to v15 release
 */

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public emailSent = false;
  public forgotPasswordForm: UntypedFormGroup;
  public isExpiredPassword = false;
  public isSubmitted = false;
  public showExpiredLinkModal: boolean;
  private title: string;

  constructor(
    private dialog: MatDialog,
    private validatorService: ValidatorService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private routeParams: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService
  ) {
    this.forgotPasswordForm = formBuilder.group({
      email: ['', [validatorService.email, Validators.required]],
      contactNumber: ['6175550161'], // Honeypot Field
      address: [''] // Honeypot Field
    });
    this.title = this.titleService.getTitle();
    this.titleService.setTitle(`${this.title} | Forgot Password`);
  }

  public ngOnInit(): void {
    const handleQueryParams = ({ expiredLink, expiredPassword }) => {
      if (expiredLink) {
        this.dialog.open(ExpiredLinkModalComponent);
      } else if (expiredPassword) {
        this.isExpiredPassword = true;
      }
    };

    this.routeParams.queryParams.subscribe(handleQueryParams);
  }

  public ngOnDestroy(): void {
    this.titleService.setTitle(`${this.title}`);
  }

  public closeLinkExpirationModal(): void {
    this.showExpiredLinkModal = false;
  }

  public onSubmit(): void {
    if (!this.forgotPasswordForm.valid || this.isInValidEmail) {
      return;
    }
    this.isSubmitted = true;
    const email = this.forgotPasswordForm.value.email;
    const contactNumber = this.forgotPasswordForm.value.contactNumber;
    const address = this.forgotPasswordForm.value.address;
    const emailConfirmationCallback = (res) => {
      if (res.message === 'The email address you entered does not exist') {
        this.toastr.error(res.message, 'Error');
      } else if (res.message === 'You have exceeded the maximum password reset attempts allowed. ' +
        'Please wait a few minutes and try again.') {
        this.toastr.error(res.message, 'Error');
      } else {
        this.emailSent = true;
        window.scrollTo(0, 0);
      }
      this.isSubmitted = false;
    };
    const requestFailedCallback = error => {
      this.toastr.error(error.message, 'Error');
      this.isSubmitted = false;
    };
    this.authService.forgotPassword(email, contactNumber, address, emailConfirmationCallback, requestFailedCallback);
  }

  public goToLogin(): void {
    this.router.navigate(['login']);
  }

  get isInValidEmail() {
    const { errors }: any = this.forgotPasswordForm.get('email');
    return (errors != undefined && errors.badFormat);
  }

}
