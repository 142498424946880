/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

// Angular Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CookieService } from 'ngx-cookie-service';

// In-App Components
import { AppComponent } from './app.component';

// In-App Modules
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FooterLinksModule } from './general/footer-links.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';

// In-App Services
import { VdsTitleService } from './service/vds-title.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/*
   *  @author     @version    @date            @description
   *  PPareek      01          Jul 25, 2024    AFLL-21763 - Upgrade Angular to v18 and Material to V18 (M3)
*/

@NgModule({
    declarations: [
        AppComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        AuthModule,
        FooterLinksModule,
        SharedModule,
        DashboardModule,
        ToastrModule.forRoot({
            autoDismiss: true,
            maxOpened: 1,
            positionClass: 'toast-top-right',
            timeOut: 3000
        }),
        NgIdleKeepaliveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (httpLoaderFactory),
                deps: [HttpClient]
            }
        })],
        providers: [
        VdsTitleService,
        CookieService,
        provideHttpClient(withInterceptorsFromDi())
    ] 
})
export class AppModule { }

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
