import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ExpiredLinkModalComponent } from './forgot-password/expired-link-modal/expired-link-modal.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyAcceptanceComponent } from './privacy-acceptance/privacy-acceptance.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FooterLinksModule } from '../general/footer-links.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MfaSendVerificationCodesComponent } from './mfa-send-verification-codes/mfa-send-verification-codes.component';
import { MFAComponent } from './MFA/MFA-component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { VerificationCodeComponent } from './MFA/verification-code/verification-code.component';
import { AuthorizationComponent } from './authorization/authorization.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ExpiredLinkModalComponent,
    ResetPasswordComponent,
    PrivacyAcceptanceComponent,
    MfaSendVerificationCodesComponent,
    MFAComponent,
    VerificationCodeComponent,
    AuthorizationComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    RouterModule,
    AuthRoutingModule,
    SharedModule,
    MatToolbarModule,
    FooterLinksModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatCardModule,
    MatDividerModule
  ],
  exports: [
    AuthComponent
  ],
  bootstrap: [
    AuthComponent
  ]
})
export class AuthModule { }
