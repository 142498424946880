import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {Observable} from 'rxjs';
import {App} from '../model/app.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private httpService: HttpService) { }

  public getApps(): Observable<App[]> {
    return this.httpService.get<App[]>(this.httpService.urls.apps, true);
  }

  public getSignedUrl(url: string, method: string): Observable<any> {
    // AFLL-13315 - Omitting https:// from url to avoid WAF detecting as RFI attack
    if (url && url.toLowerCase().startsWith('https://')) {
      url = url.substring(8);
    }
    return this.httpService.post(this.httpService.urls.signedUrl, { url, method }, true);
  }
}
