import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatCardModule } from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatCardModule,
    TranslateModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    LoadingSpinnerComponent,
    TranslateModule
  ]
})

export class SharedModule { }
