<div class="hp-hcp-auth-app">
  <div class="signin-wizard" *ngIf="isLoginFormShown">
    <div class="login-logo-row text-center">
      <img class="login-logo" src="../../../assets/img/velys-vds-logo.png" alt="velys Logo signin" />
    </div>
    <br />
    <div *ngIf="!isSessionValid" class="login-session-error signin-error-center-model">
      <p translate>
        login.user_in_another_location
      </p>
    </div>
    <div *ngIf="isInactiveLogout" class="signin-inactive-error signin-error-center-model">
      <p translate>
        login.session_inactive
      </p>
    </div>
    <form [formGroup]="loginForm" class="login-form">
      <div class="input-signin-but text-center">
        <button mat-raised-button color="primary" class="login-button" (click)="redirectToAuthUrl()" [disabled]="isSubmitted">
          {{ 'login.sign_in' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
