<section *ngIf="country != 'ANZ'" class="contact-doc col-md-offset-2">
    <h4 class="title-section">Contact Us</h4>

    <div>
        If you have questions or concerns, please email us at
        <a href={{customerSupportEmailLink}}>{{customerSupportEmail}}</a> <br/>for assistance.
    </div>
    <div>
        <br>
        <p>You can also call us at <a href="tel:{{customerSupport.phone}}">{{customerSupport.phone_display}}</a>.</p>
        <p>Customer service hours: {{customerSupport.hours}}</p>
    </div>
    <div *ngIf="selectedLang == 'en-GB'">Dear Doctor/Hospital, if you have a question about device use or would like to <br/> highlight a product complaint, please reach out to your Supplier point of contact <br/> and comply with your local regulations in terms of notification.</div>
    <div>
         <br/> <b>Product Code:</b> {{productCode}}
        <br/> <b>Version:</b> v{{version}}
    </div>
</section>
<section *ngIf="country == 'ANZ'" class="contact-doc col-md-offset-2">
    <h4 class="title-section">Contact Us</h4>
    <div>
        If you have questions or concerns, please email us on
        <a href={{customerSupportEmailLink}}>{{customerSupportEmail}}</a> <br>between {{customerSupport.hours}}.
        We will <br>aim to respond within 24 hours.
    </div>
    <div>
         <br/> <b>Product Code:</b> {{productCode}}
        <br/> <b>Version:</b> v{{version}}
    </div>
</section>
