/**
 * Copyright: Copyright © 2021
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { HostListener, Component, OnInit } from '@angular/core';
import { RedirectService } from '../../service/redirect.service';
import { SessionService } from '../../service/session.service';
import { AuthService } from '../../service/auth.service';


/*
 * @author      @version    @date           @description
 * HSenevir     01          May 10, 2023    AFLL-17913 - Fixed icon placement issue due to Angular upgrade
 * LMudalig     02          Dec 15, 2023    AFLL-19610 Sonar | VDS Portal | Removed duplicates - '@angular/core'
 * HSenevir     03          Jan 26, 2024    AFLL-20257 - Enabled Okta session logout
 */

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
  host: {
    '(document:click)': 'closeMenuOnFocusOut($event)'
  }
})
export class HeaderComponent implements OnInit {

  public username = 'My Profile';
  public isAuthenticated = false;
  public showDropdownMenu = false;

  constructor(
    private redirectService: RedirectService,
    private sessionService: SessionService,
    private authService: AuthService
  ) {
  }

  public ngOnInit(): void {
    const userSession = this.sessionService.getUserSession();
    this.isAuthenticated = userSession != null;
    if (userSession) {
      this.username = userSession.firstName + ' ' + userSession.lastName;
    }
  }


  public goToDashboard(): void {
    this.authService.processSuccessfulAuthNavigation();
  }

  public goToMyProfile(): void {
    this.redirectService.internalNavigate(this.redirectService.urls.profile);
  }

  public toggleActiveMenu(e): void {
    if (e && (e.target.id === 'user-options' || (e.target.parentElement && e.target.parentElement.id === 'user-options'))) {
      this.showDropdownMenu = !this.showDropdownMenu;
    }
  }

  public logout(): void {
    this.authService.logout(response => {
        this.authService.removeUserData();
        this.authService.executeOktaLogout();
    },
      error => {
        this.authService.removeUserData();
        this.authService.executeOktaLogout();
        this.redirectService.navigate('');
      });
  }

  @HostListener('document:click', ['$event'])
  documentClick(event): void {
    const isProfileMenuFocusedOut = event.target.id !== '' ? event.target.id !== 'user-options' :
      event.target.parentElement && event.target.parentElement.id !== 'user-options';
    if (isProfileMenuFocusedOut) {
      this.showDropdownMenu = false;
    }
  }
}
