/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { CustomerSupportModel } from '../app/model/customer-support.model';

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Feb 10, 2023    AFLL-20737 Added isAnz variable
 *  EElangav    02          Feb 22, 2024    AFLL-20712 Added Localization of Customer Support contact details for ANZ
 *  PPareek     03          Mar 27, 2024    AFLL-21186 - Remove isVDP if/else code from our services.
 */
export class AppConstants {
  private static isANZRegionHostnameValue: boolean;
  private static isAUHostnameValue: boolean;
  private static isNZHostnameValue: boolean;
  private static isUKHostnameValue: boolean;
  private static regionByHostNameValue: string;
  static validUSProdPublicURL = 'https://www.velys.jnj';
  static redirectingUSProdPublicHost = 'velys.jnj';
  static ipify_api_enabled;
  static ipify_api_max_timeout;

  static get ipifyApiEnabled() {
    return this.ipify_api_enabled;
  }
  static set ipifyApiEnabled(value) {
     this.ipify_api_enabled = value;
  }

  static get ipifyApiMaxTimeout() {
    return this.ipify_api_max_timeout;
  }
  static set ipifyApiMaxTimeout(value) {
     this.ipify_api_max_timeout = value;
  }

  static get isANZRegionHostname(): boolean {
    return this.isANZRegionHostnameValue;
  }

  static set isANZRegionHostname(value) {
    this.isANZRegionHostnameValue = value;
  }

  static get isUKHostname(): boolean {
    return this.isUKHostnameValue;
  }

  static set isUKHostname(value) {
    this.isUKHostnameValue = value;
  }

  static get isAUHostname(): boolean {
    return this.isAUHostnameValue;
  }

  static set isAUHostname(value) {
    this.isAUHostnameValue = value;
  }

  static get isNZHostname(): boolean {
    return this.isNZHostnameValue;
  }

  static set isNZHostname(value) {
    this.isNZHostnameValue = value;
  }

  private static regionValue;

  static get region() {
    return this.regionValue;
  }

  static set region(value) {
    this.regionValue = value;
  }

  public static get productCode(): string {
    return '451570507';
  }

  public static get regionByHostName() {
    if (this.isUKHostname) {
      this.regionByHostNameValue = 'UK';
    } else if (this.isANZRegionHostname) {
      this.regionByHostNameValue = 'ANZ';
    } else {
      this.regionByHostNameValue = 'US';
    }
    return this.regionByHostNameValue;
  }

  public static get customerSupport(): CustomerSupportModel {

    const CUSTOMERSUPPORT = {
      US: {
        phone_display: '1-800-689-0746',
        phone: '+18006890746',
        hours: '7 AM-7 PM EST, Monday-Friday'
      },
      UK: {
        phone_display: '0800 917 6822',
        phone: '08009176822',
        hours: '9 AM-6 PM, Monday-Friday'
      },
      ANZ: {
        hours: '9 AM-5 PM Australian Eastern Standard Time (AEST), Monday-Friday'
      }
    };
    return CUSTOMERSUPPORT[this.regionByHostName];
  }

  public static get customerSupportEmail(): string {

    const CUSTOMERSUPPORTEMAIL = {
      US: 'info@velysinsights.com',
      UK: 'info-uk@velysinsights.com',
      ANZ: 'VPPANZHelpDesk@its.jnj.com'
    };
    return CUSTOMERSUPPORTEMAIL[this.regionByHostName];
  }

  public static get languageFile(): string {
    const LANGUAGE = {
      US: 'en-US',
      UK: 'en-GB',
      ANZ: 'en-GB'
    };
    return LANGUAGE[this.regionByHostName];
  }

  public static apps = {
    careCoordination: 'Care Coordination',
    patientPath: 'VELYS™ Patient Path - For HCPs',
    velysInsightsAnalytics: 'VELYS™ Insights'
  };

  private static ipAddressValue: string;

  public static get ipAddress(): string {
    return this.ipAddressValue;
  }

  public static set ipAddress(ipAddress) {
    this.ipAddressValue = ipAddress;
  }
}

export const COOKIE_DATES = {
  us: ' 7/4/2022.',
  uk: '01 April 2022'
};
