/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FooterLinksComponent } from './footer-links.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePoliciesComponent } from './cookie-policies/cookie-policies.component';
import { UKPrivacyPolicyComponent } from './privacy-policy-uk/privacy-policy-uk.component';
import { DepuySynthesEmployeePrivacyPolicyComponent } from './depuy-synthes-employee-privacy-policy/depuy-synthes-employee-privacy-policy.component';
import { ANZPrivacyPolicyComponent } from './privacy-policy-anz/privacy-policy-anz.component';
/*
 *  @author     @version    @date           @description
 *  EElangav    01          Feb 14, 2024    AFLL-20711 ANZ | Localize Privacy Policy copy  for a HCP user 
 */
const routes: Routes = [
  {
    path: '',
    component: FooterLinksComponent,
    data: {pageTitle: 'Footer'},
    children: [
      {
        path: 'contact-us',
        component: ContactUsComponent,
        data: {
          pageTitle: 'Contact Us'
        }
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
          pageTitle: 'Privacy Policy'
        }
      },
      {
        path: 'uk-privacy-policy',
        component: UKPrivacyPolicyComponent,
        data: {
          pageTitle: 'Privacy Policy'
        }
      },
      {
        path: 'cookie-policies',
        component: CookiePoliciesComponent,
        data: {
          pageTitle: 'Cookie Policies'
        }
      },
      {
        path: 'depuy-synthes-employee-privacy-policy',
        component: DepuySynthesEmployeePrivacyPolicyComponent,
        data: {
          pageTitle: 'Privacy Policy'
        }
      },
      {
        path: 'anz-privacy-policy',
        component: ANZPrivacyPolicyComponent,
        data: {
          pageTitle: 'Privacy Policy'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FooterLinksRoutingModule {
}
