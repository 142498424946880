import { Component, OnInit } from '@angular/core';
import { AppService } from '../service/app.service';
import { App } from '../model/app.model';
import { AppConstants } from '../../config/constants';
import { AuthService } from '../service/auth.service';

/*
 * @author    @version    @date           @description
 * HSenevir   01          Oct 25, 2023    AFLL-20061 - Added Session verifier for launch buttons
 */

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public apps: App[] = [];
  constructor(
    private appService: AppService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.appService.getApps().subscribe(response => {
      // removing care-coordination app from apps list to avoid showing it in dashboard
      const careCoordinationAppIndex = response.findIndex((object) => {
        return object.title === AppConstants.apps.careCoordination;
      });
      if (careCoordinationAppIndex !== -1) {
        response.splice(careCoordinationAppIndex, 1);
      }
      this.apps = response.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });
    }, error => {
    });
  }

  public navigateTo(app: App): void {
    this.authService.verifySession(() => {
      if (app.authUrl && app.authUrl !== '' && (!app.href || app.href.closed)) {
        if (app.title.toLowerCase() === AppConstants.apps.careCoordination.toLowerCase()) {
          this.appService.getSignedUrl(app.authUrl, 'post').subscribe(response => {
            this.focusOrOpenTabForUrl(response.url, app);
          });
        }
      } else {
        this.focusOrOpenTabForUrl(app.ssoUrl, app);
      }
    });
  }

  private focusOrOpenTabForUrl = (link: string, app: App): void => {
    if (!app.href || app.href.closed) {
      app.href = window.open(link, app.name);
    } else {
      app.href.focus();
    }
  }
}
