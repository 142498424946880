import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectService } from '../../service/redirect.service';
import { AuthService } from '../../service/auth.service';
import { SessionService } from '../../service/session.service';
import { CookieUtilityServiceService } from '../../service/cookie-utility-service.service';

/*
 * @author          @version    @date             @description
 * HSenevir         01          Sep 14, 2023      AFLL-18353 - Supporting DocSpera mobile app login with VDP login flow
 * HSenevir         02          Feb 28, 2024      AFLL-20908 - Updated logic to use isLoadingFromDocsperaMobileApp function
 */
@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {

  public isErrorMessageShown = false;
  public errorMessage = '';

  constructor(
    private routeParams: ActivatedRoute,
    private redirectService: RedirectService,
    private authService: AuthService,
    private sessionService: SessionService,
    private cookieUtilityService: CookieUtilityServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const isFromDocsperaMobileApp = this.authService.isLoadingFromDocsperaMobileApp();
    this.routeParams.queryParams.subscribe(({code, error}) => {
      if (code) {
        this.authService.authorize(code, isFromDocsperaMobileApp, response => {
          const userProfile = this.sessionService.getUserSession();
          if (!userProfile.privacyPolicyAccepted) {
            this.redirectService.internalNavigate(this.redirectService.urls.privacyAcceptance);
          } else {
            this.authService.processSuccessfulAuthNavigation(true, null, isFromDocsperaMobileApp);
          }
        }, error => {
          // AFLL-12835 - handling scenario if access tokens are invalid, redirect to log in screen and display toast message
          if (error && error.message && error.message.toLowerCase().includes('invalid format')) {
            const acError = true;
            this.router.navigate([this.redirectService.urls.login], {queryParams: {acError}});
          } else {
            this.isErrorMessageShown = true;
            this.errorMessage = 'login.authorizationFailed';
          }
        });
      } else {
        this.isErrorMessageShown = true;
        this.errorMessage = 'login.authorizationFailed';
      }
    });
  }

  public navigateToLogin(): void {
    this.redirectService.internalNavigate(this.redirectService.urls.login);
  }

}
