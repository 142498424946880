import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-uk-privacy-policy',
    templateUrl: './privacy-policy-uk.html',
    styleUrls: ['./privacy-policy-uk.scss', '../footer-links-common.scss']
})

export class UKPrivacyPolicyComponent {
  @Input() public isUsedInAcceptance = false;
}
