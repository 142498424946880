<section class="large-doc">
    
    <h4 class="title-section" translate>Cookie Policy</h4>

    <p translate>This Cookie Policy is designed to tell you about our practices regarding the collection of information through cookies and other tracking technologies (like gifs, web beacons, etc.).<p>
    <p translate>The functionalities for which we use these technologies may include the following:</p>

    <ul class="list-style" translate>
        <li translate>Assisting you in navigation,</li>
        <li translate>Assisting in registering for our events, login, and your ability to provide feedback,</li>
        <li translate>Analyzing the use of our products, services or applications,</li>
        <li translate>Assisting with our promotional and marketing efforts (including behavioral advertising),</li>
        <li translate>Offering content of third parties (such as social media content).</li>
    </ul>

    <p translate><br>Below is a detailed list of the cookies we use, with a description. We classify cookies in the following categories:</p>

    <ul class="list-style" translate>
        <li translate>Strictly Necessary Cookies</li>
        <li translate>Performance Cookies</li>
        <li translate>Functional Cookies</li>
        <li translate>Targeting Cookies</li>
    </ul>

    <p translate><br>Except where allowed by applicable law, we place cookies after having received your consent through the cookie banner or preference center. You can change your cookie settings per cookie category (except for strictly necessary cookies that are required for the proper functioning of the site) at any time by clicking on the “cookie settings” button below:</p>

    <p class="sub-title" translate>Links to other websites</p>
    <p translate>This site may contain links or references to other websites. Please be aware that we do not control the cookies / tracking technologies of other websites and this Cookie Policy does not apply to those websites.</p>

    <p class="sub-title" translate>How to Contact Us</p>
    <p translate>If you have any questions, comments, or concerns about this Cookie Policy or the information practices of this site, please use the contact information included in our Privacy Policy.</p>

    <p class="sub-title" translate>Changes to This Cookie Policy</p>
    <p translate>If this Cookie Policy changes, the revised policy will be posted on this site. This Cookie Policy was last updated <span translate class="bold-text"> {{cookieUpdatedDate}}</span></p>

</section>

