import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidatorService } from 'src/app/service/validator.service';
import { RedirectService } from '../../service/redirect.service';
import { AuthService } from '../../service/auth.service';
import { SessionService } from '../../service/session.service';

/*
 * @author          @version    @date           @description
 * JVeerasa         01          2023-03-01      AFLL-16881 - VDS Portal | Upgrade Angular to v15 release
 */

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  public errors = {
    newPasswordError: undefined,
    confirmPasswordError: undefined
  };

  public passwordResetConfirm = false;
  public resetPasswordForm: UntypedFormGroup;
  public submitted = false;
  public submittedAndAllowed = false;
  public isNewPasswordHidden = true;
  public isConfirmPasswordHidden = true;
  private title: string;
  private titleService: Title;
  private currentPassword: string;
  private messages = require('../../shared/utility/strings/strings.en.json');

  constructor(
    titleService: Title,
    private router: Router,
    private routeParams: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private validatorService: ValidatorService,
    private redirectService: RedirectService,
    private authService: AuthService,
    private sessionService: SessionService
  ) {
    this.resetPasswordForm = formBuilder.group({
      newPassword: [''],
      confirmedPassword: ['']
    });
    this.titleService = titleService;
    this.title = this.titleService.getTitle();
    this.titleService.setTitle(`${this.title} | Reset Password`);

    if (this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.currentPassword) {
      this.currentPassword = this.router.getCurrentNavigation().extras.state.currentPassword;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(`${this.title}`);
  }

  private clearInputErrors(): void {
    this.errors = {
      newPasswordError: undefined,
      confirmPasswordError: undefined
    };
  }

  public goToLogin(): void {
    const userProfile = this.sessionService.getUserSession();
    this.redirectService.navigate(userProfile.authorizationUrl);
  }

  public resetPassword(): void {
    this.errors = {
      newPasswordError: undefined,
      confirmPasswordError: undefined
    };
    this.submitted = true;
    const password = this.resetPasswordForm.value.newPassword;
    const confirmedPassword = this.resetPasswordForm.value.confirmedPassword;
    const isPasswordValid = this.isPasswordValid(password);
    const isConfirmedPasswordValid = this.isConfirmedPasswordValid(password, confirmedPassword);

    if (!isPasswordValid || !isConfirmedPasswordValid) {
      return;
    }

    this.submittedAndAllowed = true;
    const userProfile = this.sessionService.getUserSession();
    this.authService.changePasswordForExpiredPassword(userProfile.stateToken, this.currentPassword, password,
      this.successfulPasswordReset.bind(this), this.failedPasswordReset.bind(this));
  }

  private isPasswordValid(password: string): boolean {
    if (!this.validatorService.passwordStrength(password)) {
      this.errors.newPasswordError = this.messages.reset_pwd_insecure;
      return false;
    }
    return true;
  }

  private failedPasswordReset({code, message}): void {
    this.clearInputErrors();
    this.submittedAndAllowed = false;
    if (message.toLowerCase().includes('state token')) {
      this.toastr.error('Token has been expired, please login again.', 'Error');
      return;
    }
    this.toastr.error(message, 'Error');
  }

  private isConfirmedPasswordValid(password: string, confirmedPassword: string): boolean {
    const isConfirmedPasswordValid = password && (password === confirmedPassword);
    if (!isConfirmedPasswordValid) {
      this.errors.confirmPasswordError = this.messages.reset_pwd_mismatch;
    }
    return isConfirmedPasswordValid;
  }

  private successfulPasswordReset(): void {
    this.submittedAndAllowed = false;
    this.passwordResetConfirm = true;
    window.scrollTo(0, 0);
  }

  public togglePasswordNew(): void {
    this.isNewPasswordHidden = !this.isNewPasswordHidden;
  }

  public togglePasswordConfirm(): void {
    this.isConfirmPasswordHidden = !this.isConfirmPasswordHidden;
  }
}
