import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.html',
    styleUrls: ['./privacy-policy.scss', '../footer-links-common.scss']
})

export class PrivacyPolicyComponent {
  @Input() public isUsedInAcceptance = false;
}
