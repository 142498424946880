import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {RedirectService} from '../service/redirect.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.html',
    styleUrls: ['./auth.scss']
})

export class AuthComponent {
    public selectedLang = "";
    public showLanguageMenu = false;
    constructor(
        private router: Router,
        public redirectService: RedirectService,
        public translateService: TranslateService,
    ) { 
        this.selectedLang = this.translateService.currentLang == 'en-US'? 'en' : this.translateService.currentLang;
    }
    
    public toggleLanguageMenu(e) {
        this.showLanguageMenu = !this.showLanguageMenu;
    }
    
    public toggleLanguage(langCode: string) {
        this.selectedLang = langCode == 'en-US' ? 'en' : langCode;
        this.showLanguageMenu = !this.showLanguageMenu;
        this.translateService.use(langCode);
    }

    public goToLogin(): void {
        this.router.navigateByUrl(this.redirectService.urls.login);
    }

    public isPrivacyPolicyAcceptancePage(): boolean {
      return this.router.url === this.redirectService.urls.privacyAcceptance;
    }

}
