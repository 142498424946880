import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private deviceService: DeviceDetectorService) { }

  isIos(): boolean {
    return (this.deviceService.isMobile() && this.deviceService.os.toLowerCase() === 'ios') ||
      (this.deviceService.isTablet() && this.deviceService.os.toLowerCase() === 'mac');
  }
}
