/**
 * Copyright: Copyright © 2021
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Observable, throwError, timeout} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SessionService} from './session.service';
import {RedirectService} from './redirect.service';
import {Router} from '@angular/router';
import { AppConstants } from '../../config/constants';

/*
 * @author          @version    @date             @description
 * HSenevir         01          Mar 16, 2023      AFLL-16968 - Exempted authorization page from 401 http code redirection to login
 * HSenevir         02          Jun 26, 2023      AFLL-18373 Implemented Ipify parameters for controlling request timeouts
 * HSenevir         03          Oct 25, 2023      AFLL-20061 - Added Session verifier for launch buttons
 * PPareek          04          Jul 25, 2024      AFLL-21763 - Upgrade Angular to v18 and Material to V18 (M3)
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  HTTP_UNAUTHORIZED = 401;
  HTTP_FORBIDDEN = 403;

  urls = {
    login: '/api/auth/login',
    authConfig: '/api/auth/config',
    logout: '/api/auth/logout',
    verifySession: '/api/auth/session/verify',
    authorize: '/api/auth/authorize',
    acceptPrivacyPolicy: '/api/auth/login/accept',
    apps: '/api/apps',
    signedUrl: '/api/apps/signed-url',
    forgotPassword: '/api/auth/forgot-password',
    changePassword: '/api/auth/change-password',
    ipAddress: 'https://api.ipify.org?format=json',
    mfaActivate: '/api/auth/mfa/activate',
    mfaSendCode: '/api/auth/mfa/send-code',
    sendVerificationCode: '/api/auth/mfa/verify',
    mfaReSendCode: '/api/auth/mfa/resend-code'
  };
  isRequestInProgress = false;

  constructor(private http: HttpClient,
              private redirectService: RedirectService,
              private sessionService: SessionService,
              private router: Router
  ) { }

  private handleError(error: HttpErrorResponse): Observable<any> {
    this.isRequestInProgress = false;
    let errorMessage = error.error || 'Server error';
    if (this.handleUnauthorizedError(error.status)) {
      const inactivity = true;
      this.sessionService.clearUserSession();
      this.router.navigate([this.redirectService.urls.login], {queryParams: {inactivity}})
        .then(() => {
          window.location.reload();
        });
      errorMessage = 'Session expired';
    }
    return throwError(errorMessage);
  }

  private handleUnauthorizedError(statusCode: number): boolean {
    let isUnauthorizedError = false;
    const exemptedFromRedirectionUrls = ['/login', '/verification-code', '/authorization'];
    let isStartingWithExemptedUrl = false;
    if (statusCode === this.HTTP_UNAUTHORIZED) {
      for (const url of exemptedFromRedirectionUrls) {
        if (this.router.url.startsWith(url)) {
          isStartingWithExemptedUrl = true;
          break;
        }
      }
      isUnauthorizedError = !isStartingWithExemptedUrl;
    }
    return isUnauthorizedError;
  }

  public get<T>(url: string, isSecure = false): Observable<T> {
    this.isRequestInProgress = true;
    let headers = new HttpHeaders();
    if (url !== this.urls.ipAddress) {
      headers = new HttpHeaders({
        'host-value': location.host
      });
    }
    const user = this.sessionService.getUserSession();
    if (isSecure && user && url !== this.urls.ipAddress) {
      headers = new HttpHeaders({
        Authorization: 'Bearer ' + user.accessToken,
        'host-value': location.host
      });
    }
    return this.http.get<T>(url, {headers}).pipe(
      map(response => {
        this.isRequestInProgress = false;
        return response;
      }),
      catchError(this.handleError.bind(this)));
  }

  public getExternal<T>(url: string, isLoaderTrackingNeeded = false): Observable<T> {
    if (isLoaderTrackingNeeded) {
      this.isRequestInProgress = true;
    }
    const headers = new HttpHeaders();
    return this.http.get<T>(url, {headers}).pipe(
      timeout(AppConstants.ipifyApiMaxTimeout * 1000),
      map(response => {
        if (isLoaderTrackingNeeded) {
          this.isRequestInProgress = false;
        }
        return response;
      }),
      catchError(this.handleError.bind(this)));
  }

  public post(url: string, requestBody: any = {}, isSecure = false): Observable<object> {
    this.isRequestInProgress = true;
    const ipAddress = AppConstants.ipAddress;
    let headers;
    if (ipAddress) {
      headers = new HttpHeaders({
        'ip-address': ipAddress,
        'host-value': location.host
      });
    } else {
      headers = new HttpHeaders({
        'host-value': location.host
      });
    }
    const user = this.sessionService.getUserSession();
    if (isSecure && user) {
      headers = new HttpHeaders({
        Authorization: 'Bearer ' + user.accessToken,
        'ip-address': ipAddress,
        'host-value': location.host
      });
    }
    return this.http.post(url, requestBody, {headers}).pipe(
      map(response => {
        this.isRequestInProgress = false;
        return response;
      }),
      catchError(this.handleError.bind(this)));
  }

  public getRequestInProgressStatus(): boolean {
    return this.isRequestInProgress;
  }
}
