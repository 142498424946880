import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { SessionService } from 'src/app/service/session.service';
import { UserStatus } from '../../model/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mfa-component',
  templateUrl: './MFA-component.html',
  styleUrls: ['./MFA-component.scss']
})
export class MFAComponent {
  public isSubmitted = false;
  public mfaFailed: string;
  public currentPassword: string;
  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private toastService: ToastrService,
    private router: Router,
    public translateService: TranslateService
  ) {
    if (this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.currentPassword) {
      this.currentPassword = this.router.getCurrentNavigation().extras.state.currentPassword;
    }
    // translation section for toast
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('termsAndUse.mfa_failed').subscribe((translated: string) => {
        this.mfaFailed = translated;
      });
    });
  }

  public mfaActivate(): void {
    const requestBody = {
      userProfileDTO: this.sessionService.getUserSession(),
      password: this.currentPassword
    };
    this.authService.mfaActivate(requestBody, response => {
      const userProfile = this.sessionService.getUserSession();
      if (userProfile.status == UserStatus.MFA_REQUIRED) {
        const currentPassword = this.currentPassword;
        this.router.navigate(['/app-mfa-send-verification-codes'], { state: { currentPassword } });
      }
    }, error => {
      this.toastService.error(this.mfaFailed, 'Error');
    });
  }
}
