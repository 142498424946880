import { Injectable } from '@angular/core';
import {SessionService} from './session.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';

/*
 *  @author     @version    @date             @description
 *  PPareek     01          Apr 24,2024       AFLL-20578 | VDS Portal | Upgrade NodeJS to V20 and Angular to V17
*/

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private sessionService: SessionService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userProfile = this.sessionService.getUserSession();
    if (!(userProfile && userProfile.accessToken)) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
