<div class="reset-password-auth-app">
  <div class="password-reset-wizard" *ngIf="!passwordResetConfirm">

    <div class="velys-logo-row text-center">
      <img class="velys-logo" src="../../../assets/img/velys-vds-logo.png" alt="velys logo reset"/>
    </div>
    <br/>

    <h1 class="title" translate>
      resetPasswordLink.reset_your_pwd
    </h1>

    <div class="password-information" translate>
      resetPasswordLink.pwd_must
      <br/>
      <ul>
        <li translate>resetPasswordLink.pwd_len</li>
        <li translate>resetPasswordLink.pwd_inc
          <ul>
            <li translate>resetPasswordLink.pwd_lowercase</li>
            <li translate>resetPasswordLink.pwd_uppercase</li>
            <li translate>resetPasswordLink.pwd_number</li>
          </ul>
        </li>
      </ul>
      <div translate>
        resetPasswordLink.pwd_restrict
        <ul>
          <li translate>resetPasswordLink.pwd_username</li>
          <li translate>resetPasswordLink.pwd_firstname</li>
          <li translate>resetPasswordLink.pwd_lastname</li>
        </ul>
      </div>
      <div translate>resetPasswordLink.pwd_common</div>
    </div>

    <form [formGroup]="resetPasswordForm" class="password-reset-form">
      <div class="input-group-row">
        <label for="newPassword">New password</label>
        <input class="form-input" id="newPassword" [type]="isNewPasswordHidden ? 'password' : 'text'"
               data-dom-id="textbox_new_password"
               formControlName="newPassword"/>
        <span class="material-icons"
              (click)="togglePasswordNew()">{{isNewPasswordHidden ? 'visibility_off' : 'visibility'}}</span>
      </div>
      <span *ngIf="submitted && errors.newPasswordError" class="error-message">
                {{ errors.newPasswordError }}
            </span>

      <div class="input-group-row">
        <label for="confirmedPassword">Re-enter new password</label>
        <input class="form-input" id="confirmedPassword" [type]="isConfirmPasswordHidden ? 'password' : 'text'"
               formControlName="confirmedPassword"
               data-dom-id="reset_password_input_confirm_password">
        <span class="material-icons"
              (click)="togglePasswordConfirm()">{{isConfirmPasswordHidden ? 'visibility_off' : 'visibility'}}</span>
      </div>

      <span *ngIf="submitted && errors.confirmPasswordError" class="error-message">
                {{ errors.confirmPasswordError }}
            </span>

      <div class="password-reset-button text-center">
        <button mat-raised-button color="primary" class="submit-button" (click)="resetPassword()"
                [disabled]="submittedAndAllowed">Submit
        </button>
      </div>
      <div class="text-center">
        <a class="cancel-link" routerLink="/login" routerLinkActive="active">Cancel</a>
      </div>

    </form>
  </div>

  <div class="password-reset-success-wizard" *ngIf="passwordResetConfirm">
    <div class="velys-logo-row text-center">
      <img class="velys-logo" src="../../../assets/img/velys-vds-logo.png" alt="velys logo reset"/>
    </div>

    <br/>

    <h1 class="title">
      Password Reset Successful!
    </h1>

    <p class="password-reset-success-message">
      Your password has been successfully reset.<br/>
      You can now log in with your new password.<br/>
    </p>

    <div class="success-return-button-container">
      <button type="button" class="button success-return-button" (click)="goToLogin()">
        Sign In
      </button>
    </div>
  </div>
</div>
