/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthModule } from '../auth/auth.module';

import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button'

/*
   *  @author     @version    @date            @description
   *  PPareek      01          Jul 25, 2024    AFLL-21763 - Upgrade Angular to v18 and Material to V18 (M3)
*/

@NgModule({
    declarations: [
        DashboardComponent
    ],
    bootstrap: [
        DashboardComponent
    ],
    imports: [
        AppRoutingModule,
        AuthModule,
        SharedModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatCardModule,
        MatButtonModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class DashboardModule { }
