<header class="app-header"  >
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <img src="../../../assets/img/velys-logo-v2.png" class="header-logo-img" alt="VELYS Digital Surgery" (click)="goToDashboard()">
            <span class="fill-space"></span>

            <div class="user-options-container" *ngIf="isAuthenticated">
                <button mat-button id="user-options" (click)="toggleActiveMenu($event)" disableRipple>
                  <span id="user-options">{{username}}</span>
                  <mat-icon id="user-options" iconPositionEnd>expand_more</mat-icon>
                </button>
                <mat-card *ngIf="showDropdownMenu" class="header-dropdown-options">
                    <div (click)="goToMyProfile()"  data-dom-id="my_settings">
                        <button class="header-menu-item">My Login Info</button>
                    </div>
                    <mat-divider class="divider-style"></mat-divider>
                    <button class="header-menu-item" (click)="logout()">Logout</button>
                </mat-card>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="header-filler"></div>
</header>
