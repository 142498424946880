import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionService } from './session.service';
import { Observable, Subject } from 'rxjs';
import { AppConstants } from '../../config/constants';
import { RedirectService } from './redirect.service';
import { AppService } from './app.service';
import { DeviceService } from './device.service';
import { ToastrService } from 'ngx-toastr';
import { CookieUtilityServiceService } from './cookie-utility-service.service';
import { AuthConfigModel } from '../model/auth.config.model';
import { UserModel } from '../model/user.model';
const strings = require('../shared/utility/strings/strings.en.json');

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Jun 26, 2023    AFLL-18373 Implemented Ipify parameters for controlling request timeouts
 *  HSenevir    02          Sep 14, 2023    AFLL-18353 - Supporting DocSpera mobile app login with VDP login flow
 *  HSenevir    03          Sep 27, 2023    AFLL-18353 - Fixed handling internal apps having auth url value while having SSO url
 *  HSenevir    04          Oct 25, 2023    AFLL-20061 - Added Session verifier for launch buttons
 *  HSenevir    05          Jan 26, 2024    AFLL-20257 - Enabled Okta session logout
 *  HSenevir    06          Feb 28, 2024    AFLL-20908 - Fixing hcp app re-login issue with ds flag and related cookie
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userLoggedIn = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private redirectService: RedirectService,
    private appService: AppService,
    private deviceService: DeviceService,
    private toastService: ToastrService,
    private cookieUtilityService: CookieUtilityServiceService
  ) {
    this.userLoggedIn.next(false);
  }

  public login(requestBody, successCallback, errorCallback): void {
    this.httpService.post(this.httpService.urls.login, requestBody)
      .subscribe((response: any) => {
        this.setUserLoggedIn(true);
        this.sessionService.setUserSession(response);
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public getAuthConfig(errorCallback): void {
    this.httpService.get<AuthConfigModel>(this.httpService.urls.authConfig)
      .subscribe((response: AuthConfigModel) => {
        this.sessionService.setSessionValue(this.sessionService.sessionKeys.authUrl, response.authUrl);
        this.redirectService.navigate(response.authUrl);
      }, error => {
        errorCallback(error);
      });
  }

  public logout(successCallback, errorCallback): void {
    this.httpService.get(this.httpService.urls.logout)
      .subscribe((response: any) => {
        this.removeUserData();
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public getPublicIpAddress(callback = null, isLoaderTrackingNeeded = false): void {
    if (AppConstants.ipifyApiEnabled) {
      this.httpService.getExternal(this.httpService.urls.ipAddress, isLoaderTrackingNeeded)
        .subscribe((response: any) => {
          AppConstants.ipAddress = response.ip;
          if (callback) {
            callback(response.ip);
          }
        }, error => {
          if (callback) {
            callback(null);
          }
        });
    } else {
      AppConstants.ipAddress = null;
    }
  }

  public removeUserData(): void {
    this.setUserLoggedIn(false);
    this.sessionService.clearUserSession();
  }

  public isOktaLogoutExecuted(): boolean {
    const logoutUrl = this.sessionService.getLogoutURL();
    return logoutUrl == null;
  }

  public executeOktaLogout(): void {
    const logoutUrl = this.sessionService.getLogoutURL();
    if (logoutUrl) {
      this.sessionService.clearLogoutUrl();
      this.redirectService.navigate(logoutUrl);
    }
  }

  public acceptPrivacyPolicy(refreshToken, successCallback, errorCallback): void {
    const requestBody = { refreshToken };
    this.httpService.post(this.httpService.urls.acceptPrivacyPolicy, requestBody)
      .subscribe((response: any) => {
        this.sessionService.setUserSession(response);
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public forgotPassword(email, contactNumber, address, successCallback, errorCallback): void {
    const requestBody = {
      email,
      contactNumber,
      address
    };
    this.httpService.post(this.httpService.urls.forgotPassword, requestBody)
      .subscribe(response => {
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public changePasswordForExpiredPassword(stateToken, currentPassword, newPassword, successCallback, errorCallback)
    : void {
    const requestBody = {
      type: 'passwordExpired',
      stateToken,
      currentPassword,
      newPassword
    };
    this.httpService.post(this.httpService.urls.changePassword, requestBody)
      .subscribe((response: any) => {
        this.sessionService.setUserSession(response);
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public changePassword(currentPassword, newPassword): Observable<any> {
    const requestBody = {
      type: 'adhoc',
      currentPassword,
      newPassword
    };
    return this.httpService.post(this.httpService.urls.changePassword, requestBody, true);
  }

  public processSuccessfulAuthNavigation(isFromLogin = false, errorCallback: any = null, isFromMobileApp = false): void {
    const userProfile = this.sessionService.getUserSession();
    if (userProfile) {
      if (isFromMobileApp) {
        // consider care Coordination only navigation if coming from iOS device
        const careCoordinationApp = userProfile.apps.filter((item) => {
          return item.title.toLowerCase() === AppConstants.apps.careCoordination.toLowerCase();
        });
        if (careCoordinationApp.length === 1) {
          this.appService.getSignedUrl(careCoordinationApp[0].authUrl, 'post').subscribe(signedUrlResponse => {
            this.redirectService.navigate(signedUrlResponse.url);
          });
        } else {
          /*
          * If user does not have access, displaying default login error message. Else log the user out in
          * the case of password reset.
          * */
          this.removeUserData();
          if (errorCallback != null) {
            errorCallback();
          }
          if (isFromLogin) {
            this.toastService.error(strings.auth_login_invalid_credentials, 'Error');
          } else {
            this.toastService.error(strings.auth_login_no_access, 'Error');
            this.redirectService.internalNavigate(this.redirectService.urls.login);
          }
        }
      } else if (userProfile.apps.length === 1) {
        const app = userProfile.apps[0];
        if (app.authUrl && app.authUrl !== '') {
          if (app.title.toLowerCase() === AppConstants.apps.careCoordination.toLowerCase()) {
            this.appService.getSignedUrl(app.authUrl, 'post').subscribe(signedUrlResponse => {
              this.redirectService.navigate(signedUrlResponse.url);
            });
          } else {
            /*
            * If there are unfiltered auth URLs for external apps, SSO URL navigation will be attempted.
            * */
            this.redirectService.navigate(app.ssoUrl);
          }
        } else {
          this.redirectService.navigate(app.ssoUrl);
        }
      } else {
        // This is where multiple apps are available hence navigating to Hub screen
        /*
        * AFLL-10650 - users having access to both Insights Portal and Care Coordination, they should be navigated to
        * Insights portal.
        * */
        let isCareCoordinationAvailable = false;
        let isVelysInsightsAvailable = false;
        let isVelysInsightsAnalyticsAvailable = false;
        let velysInsightsAppUrl = null;
        userProfile.apps.forEach(app => {
          if (app.title.toLowerCase() === AppConstants.apps.patientPath.toLowerCase()) {
            isVelysInsightsAvailable = true;
            velysInsightsAppUrl = app.ssoUrl;
          } else if (app.title.toLowerCase() === AppConstants.apps.careCoordination.toLowerCase()) {
            isCareCoordinationAvailable = true;
          } else if (app.title.toLowerCase() === AppConstants.apps.velysInsightsAnalytics.toLowerCase()) {
            isVelysInsightsAnalyticsAvailable = true;
          }
        });
        if ((isCareCoordinationAvailable && isVelysInsightsAvailable) && !isVelysInsightsAnalyticsAvailable) {
          this.redirectService.navigate(velysInsightsAppUrl);
        } else {
          this.redirectService.internalNavigate(this.redirectService.urls.dashboard);
        }
      }
    } else {
      this.redirectService.internalNavigate(this.redirectService.urls.dashboard);
    }
  }

  private setUserLoggedIn(userLoggedIn: boolean): void {
    this.sessionService.setLoggedIn(userLoggedIn);
    this.userLoggedIn.next(userLoggedIn);
  }

  public getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  public mfaActivate(requestBody, successCallback, errorCallback): void {
    this.httpService.post(this.httpService.urls.mfaActivate, requestBody)
      .subscribe((response: any) => {
        this.setUserLoggedIn(true);
        this.sessionService.setUserSession(response);
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public mfaSendCode(requestBody, successCallback, errorCallback): void {
    this.httpService.post(this.httpService.urls.mfaSendCode, requestBody)
      .subscribe((response: any) => {
        this.setUserLoggedIn(true);
        this.sessionService.setUserSession(response);
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public sendVerificationCode(requestBody, successCallback, errorCallback): void {
    this.httpService.post(this.httpService.urls.sendVerificationCode, requestBody)
      .subscribe((response: any) => {
        this.setUserLoggedIn(true);
        this.sessionService.setUserSession(response);
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }
  public mfaReSendCode(requestBody, successCallback, errorCallback): void {
    this.httpService.post(this.httpService.urls.mfaReSendCode, requestBody)
      .subscribe((response: any) => {
        this.setUserLoggedIn(true);
        this.sessionService.setUserSession(response);
        successCallback(response);
      }, error => {
        errorCallback(error);
      });
  }

  public authorize(authorizationCode, isFromDocsperaMobileApp, successCallback, errorCallback): void {
    // AFLL-14218 Since WAF is blocking -- characters, breaking value into chunks using -- joining back from backend
    const authorizationCodeChunks = authorizationCode.split('--');
    // Since this instantly getting called, need to get ip address first and call the API.
    this.getPublicIpAddress(() => {
      this.httpService.post(this.httpService.urls.authorize, {
        authorizationCode: authorizationCodeChunks,
        isFromMobile: isFromDocsperaMobileApp
      })
        .subscribe((response: UserModel) => {
          this.setUserLoggedIn(true);
          this.sessionService.setUserSession(response);
          this.sessionService.setLogoutURL(response.logoutUrl);
          successCallback(response);
        }, error => {
          errorCallback(error);
        });
    }, true);
  }

  public verifySession(sucess): void {
    this.httpService.get(this.httpService.urls.verifySession)
      .subscribe(() => {
        sucess();
      });
  }

  public isLoadingFromDocsperaMobileApp(): boolean {
    return this.cookieUtilityService.check(this.cookieUtilityService.keys.ds) ?
      JSON.parse(this.cookieUtilityService.getCookie(this.cookieUtilityService.keys.ds)) : false;
  }

}
