<section class="large-doc">
    <h4 class="title-section" *ngIf="!isUsedInAcceptance">
        Privacy Policy
    </h4>
    <div>
        <p class="sub-title">GENERAL </p>
        <p>
            Your privacy is important to your healthcare institution (referred to as “your institution” or “institution”) and to the Johnson & Johnson family of companies, here represented by Johnson & Johnson Medical UK Ltd (“Johnson & Johnson” and jointly referred to as “we” or “us”), a UK entity which has DePuy Ireland UC as representative in the EU. This privacy statement informs you of how we process your personal data when using or accessing certain online services and facilities we make available as part of our Digital Solutions Platform (“Cloud Services”). Personal data is defined as all information that would allow us to identify you either directly or indirectly. It is important to us that you understand how we will handle your personal data and that you are aware of your rights.
        </p>
        <p>We may amend this privacy statement at any time. Notification of changes to this privacy statement will be posted on the login page. This privacy statement was last updated on 02 March, 2023.</p>

       
        <p class="sub-title">OUR IDENTITY AND ROLE</p>
        <p>
            Your institution is the Data Controller with regards to the processing of your personal data as described in this privacy statement. Johnson & Johnson acts as a Data Processor of your personal data on behalf of your institution.
        </p>

        <p class="sub-title ">WHAT PERSONAL DATA DO WE COLLECT ON BEHALF OF YOUR INSTITUTION, HOW DO WE COLLECT IT, AND
            WHY? </p>

        <p><i>Registration</i></p>
        <p>
            Institution has created an account to use our Cloud Services on your behalf. Johnson & Johnson receives your personal information which includes, but is not limited to: full name, email address, phone number (optional), institution name and institution mailing address that you enter in the registration form. We use this information to confirm your registration and enable you to make use of the Cloud Services. If you do not provide us these personal data, you will not be able to use the Cloud Services. 
        </p>
        <p><i>ViPP</i></p>
       <p>
            In order to fulfil our obligations to your Institution, ViPP processes other personal information that you or your Health Care Professional provide in addition to registration data (depending on role):
        </p>
        <ul class="list-color">
            <li>Personal Health data</li>
            <li>Communication between patient and Health Care Professional</li>
        </ul>
        <p>This processing of personal data is necessary for the purposes of the legitimate interest pursued by us to perform the contract that we have entered into with your institution for governing the use of the Cloud Services (article 6(1)(f) and article 9(2)(h) GDPR). If you do not provide us these personal data, you will not be able to use the Cloud Services.</p>

        <p><i>Usage data</i></p>
        <p>
            We also process information about how you access and use the Cloud Services ("Usage Data"). This processing of personal data is necessary for the purposes of the legitimate interest pursued by us to administer and improve the Cloud Services (article 6(1)(f) GDPR).
        </p>
        <p><i>Automatic Information Collection and Use</i></p>
        <p>We and our service providers may collect certain information automatically as you navigate around the Service. Please read the <a target="_blank" rel="opener" href="/#/general/cookie-policies" class="link">Cookie Policy</a> for detailed information about the cookies and other tracking technologies used on the Service. Except where allowed by applicable law, we place cookies after having received your consent through the cookie banner or preference center. The Cookie Policy includes information on how you may disable these technologies.           
        </p>
        <p>We and our service providers may also automatically collect and use information in the following ways: Through your browser: Certain information is collected by most browsers, such as your Media Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating system name and version, and Internet browser type and version. We may collect similar information, such as your device type and identifier, if you access the Service through a mobile device. We use this information to ensure that the Service functions properly. IP address: Your IP address is a number that is automatically assigned to your computer by your Internet Service Provider. An IP address is identified and logged automatically in our server log files whenever a user visits the Service, along with the time of the visit and the pages visited. Collecting IP addresses is standard practice and is done automatically by many online services. We use IP addresses for purposes such as calculating Service usage levels, diagnosing server problems, and administering the Service. We may also derive your approximate location from your IP address. Device Information: We may collect information about your mobile device, such as a unique device identifier, to understand how you use the Service.
        </p>

        <p class="sub-title ">WITH WHOM DO WE SHARE YOUR PERSONAL DATA? </p>
        <p>The processing of personal data as referred to in this privacy statement may, at the instruction and on behalf of your institution, be carried out (in part) by external service providers such as IT service providers or mobile app providers, through which you may also have access to certain services related to the Cloud Services. Johnson & Johnson has entered into appropriate data processing agreements with such third parties, in protection of your interests.
        </p>
        <p>The parties that we have engaged as processors for ViPP include:</p>
        <ul class="list-color">
            <li>Medical Device Business Services, Inc.: the Johnson & Johnson legal entity that acts as processing entity for the VELYS&trade; Patient Path Technology </li>
            <li>Telerx Marketing Inc d/b/a C3i Solutions, an HCL Company: the first line support provider for ViPP</li>
            <li>Okta Software is the provider of software used for identity & access management services to Johnson and Johnson for users of VELYS&trade; Patient Path - Patients</li>
            <li>Google Firebase is used to push in-app notifications to the patient mobile app </li>
            <li>Validic is used to collect and share “steps” data when a user opts in via the patient mobile add to share said data from Apple Health or Fitbit </li>
            <li>Mailgun Technologies, Inc. provider of Mail Jet which is the email service used to email users </li>
        </ul>
        <p class="sub-title ">HOW LONG DO WE RETAIN YOUR PERSONAL DATA? </p>
        <p>
            We retain your personal data no longer than necessary for the purposes for which the personal data are processed. The retention period may differ for each individual purpose and as your Institution specifies.
        </p>
        <p>If you have created an account, we retain your personal data for the purposes of your registration until you delete your account.</p>

        <p>We may retain your personal data longer if that is dictated by a statutory retention obligation or if that is necessary to protect our rights, for example in case of a (potential) legal dispute.</p>

        <p class="sub-title ">SECURITY AND LOCATION OF PROCESSING </p>

        <p> We have implemented appropriate technical and organizational measures to secure your personal data. Our systems and software are secured in order to prevent unauthorized parties inside and outside our organization from gaining access to your personal data. Access to your personal data is limited to persons who require this access for the purposes of the performance of their work.</p>

        <p>Johnson & Johnson may transfer your personal data to various geographic locations, including countries located outside your country of residence. To the extent your personal data is transferred to third countries we have put appropriate contractual and other measures in place to protect your personal data when it is transferred. You may obtain a copy of these measures by contacting Johnson & Johnson’s Data Protection Officer responsible for your country or region, if applicable, at <a
            href="mailto:emeaprivacy@its.jnj.com">emeaprivacy&#64;its.jnj.com.</a>  </p>

            <p class="sub-title ">WHAT ARE YOUR RIGHTS AND HOW DO YOU CONTACT US TO EXERCISE THEM? </p>
            <p>You have certain rights in connection with your personal data.</p>
            <p>If you would like to review, correct, update, restrict, object to the processing or delete personal data, or if you would like to receive an electronic copy of the personal data you have provided, you should contact your health care institution. </p>
            <p>You may, if applicable, be entitled to submit a complaint to the UK data protection authority.</p>
            <p>Please click below for contact information.</p>

        <p class="contact-us"><b>UK:&nbsp; <a target="_blank" href="https://ico.org.uk/">https://ico.org.uk/</a></b></p>
    </div>
</section>