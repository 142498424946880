/**
 * Copyright: Copyright © 2021
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UserModel } from '../../model/user.model';
import { SessionService } from '../../service/session.service';
import { AppConstants, COOKIE_DATES } from '../../../config/constants';

/*
 * @author      @version    @date           @description
 * LMudalig     01          Dec 15, 2023    AFLL-19610 Sonar | VDS Portal | Removed redundant jump
 */

@Component({
  selector: 'app-cookie-policies',
  templateUrl: './cookie-policies.component.html',
  styleUrls: ['./cookie-policies.component.scss']
})
export class CookiePoliciesComponent implements OnInit {
  language: any = '';
  public userSession: UserModel;
  private country: string;
  public selectedLang = '';
  public cookieUpdatedDate = '';

  constructor(
    private translateService: TranslateService,
    private userSessionService: SessionService,
  ) { this.language = this.translateService.currentLang; }

  ngOnInit(): void {
    this.userSession = this.userSessionService.getUserSession();
    this.setCountry();
    this.cookieUpdatedDate = COOKIE_DATES[this.country.toLowerCase()];
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.language = event.lang;
    });
  }

  public setCountry(): void {
    if (this.userSession && this.userSession.organization) {
      this.country = this.userSession.organization.country ? this.userSession.organization.country : environment.country;
    } else {
      this.country = AppConstants.region;
    }
  }

}
