import {Component} from '@angular/core';

/*
 * @author          @version    @date           @description
 * LMudalig         01          Dec 15, 2023    AFLL-19610 Sonar | VDS Portal | Removed unexpected empty constructor.
 */

@Component({
    selector: 'app-footer-links',
    templateUrl: './footer-links.html',
    styleUrls: ['./footer-links.scss']
})

export class FooterLinksComponent {

}
