import { Component, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../service/auth.service';
import { SessionService } from '../../../service/session.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserStatus } from '../../../model/user.model';
import { RedirectService } from '../../../service/redirect.service';

/*
 * @author          @version    @date           @description
 * JVeerasa         01          2023-03-01      AFLL-16881 - VDS Portal | Upgrade Angular to v15 release
 */

@Component({
    selector: 'app-verification-code-component',
    templateUrl: './verification-code.component.html',
    styleUrls: ['./verification-code.component.scss']
})

export class VerificationCodeComponent {

    private currentPassword: string;
    public codeDigits = '';
    public input1 = '';
    public input2 = '';
    public input3 = '';
    public input4 = '';
    public input5 = '';
    public input6 = '';
    public successToast: MatSnackBarConfig<any> = { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: ['success-snack-toaster'] };
    public failureToast: MatSnackBarConfig<any> = { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: ['failure-snack-toaster'] };
    public unSuccessfulFailureToast: MatSnackBarConfig<any> = { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: ['unsuccessful-failure-snack-toaster'] };
    public codeSentToast = '';
    public incorrectCodeToast = '';
    public codeExpiredToast = '';
    public accountLockedToast = '';

    public codeForm = this.formBuilder.group({
        codeInput1: new UntypedFormControl('', Validators.required),
        codeInput2: new UntypedFormControl('', Validators.required),
        codeInput3: new UntypedFormControl('', Validators.required),
        codeInput4: new UntypedFormControl('', Validators.required),
        codeInput5: new UntypedFormControl('', Validators.required),
        codeInput6: new UntypedFormControl('', Validators.required),
    });
    constructor(
        private router: Router,
        private redirectService: RedirectService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private sessionService: SessionService,
        public translateService: TranslateService,
        private toaster: MatSnackBar) {
        if (this.router.getCurrentNavigation().extras.state &&
            this.router.getCurrentNavigation().extras.state.currentPassword) {
            this.currentPassword = this.router.getCurrentNavigation().extras.state.currentPassword;
        }
        this.translateService.stream('verifyCodeComponent').subscribe((translations) => {
            this.incorrectCodeToast = translations.incorrectCodeToast;
            this.codeExpiredToast = translations.codeExpiredToast;
            this.accountLockedToast = translations.accountLockedToast;
        })
    }

    ngAfterViewInit() {
        const email = this.sessionService.getUserSession().email;
        this.translateService.get('verifyCodeComponent.codeSentToast', { 'param1': email }).subscribe((codeSentMessage) => {
            if (this.sessionService.getUserSession().status != UserStatus.SUCCESS) {
                this.toaster.open(codeSentMessage, null, this.successToast);
            }
        });
    }

    @HostListener('paste', ['$event'])
    handlePasteEvent(event) {
        let textArray;
        var pastedText = '';
        const { clipboardData } = event;
        if (((window as any).clipboardData) && ((window as any).clipboardData.getData)) {
            pastedText = (window as any).clipboardData.getData('Text');
        } else if (clipboardData && clipboardData.getData) {
            pastedText = clipboardData.getData('text/plain');
        }
        if (pastedText.match(/[^\d]/)) {
            event.preventDefault();
        } else {
            textArray = pastedText.split('');
        }
        if (textArray) {
            this.input1 = textArray[0];
            this.input2 = textArray[1];
            this.input3 = textArray[2];
            this.input4 = textArray[3];
            this.input5 = textArray[4];
            this.input6 = textArray[5];
        }
    }

    onKeyDown(event) {
        const key = event.which || event.keyCode;
        if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
            return true;
        }
        if (key != 8 && (key < 48 || key > 57)) {
            return false;
        }
    }

    goToNextInput(event) {
        const key = event.which || event.keyCode;
        let siblingInput = event.target.nextSibling;
        if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
            return true;
        }
        if (key != 8 && (key < 48 || key > 57)) {
            return false;
        }
        if (key != 8 && siblingInput) {
            siblingInput.select();
        }
    }

    reSendCode() {
        this.codeForm.reset();
        this.input1 = this.input2 = this.input3 = this.input4 = this.input5 = this.input6 = '';
        this.authService.mfaReSendCode(this.sessionService.getUserSession() , response => {
            this.translateService.get('verifyCodeComponent.codeSentToast', { 'param1': response.email }).subscribe((codeSentMessage) => {
                this.toaster.open(codeSentMessage, null, this.successToast);
            });
         }, error => {
            if (error.message == 'User Locked') {
                this.codeForm.reset();
                this.toaster.open(this.accountLockedToast, null, this.unSuccessfulFailureToast);
            }
         });
    }

    onSubmit() {
        let value = this.codeForm.value;
        this.codeDigits = value.codeInput1 + value.codeInput2 + value.codeInput3 + value.codeInput4 + value.codeInput5 + value.codeInput6;
        this.sendCodeToService(this.codeDigits);
    }

    public sendCodeToService(codeDigits: string): void {
        const requestBody = {
            userProfileDTO: this.sessionService.getUserSession(),
            passCode: codeDigits
        };
        this.authService.sendVerificationCode(requestBody, response => {
            const userProfile = this.sessionService.getUserSession();
            if (userProfile.status == UserStatus.PASSWORD_EXPIRED) {
                this.navigateToResetPassword(this.currentPassword);
            } else {
              this.redirectService.navigate(userProfile.authorizationUrl);
            }
        }, error => {
            if (error.message == 'The 6 digit code you entered is incorrect. Please try again') {
                this.codeForm.reset();
                this.toaster.open(this.incorrectCodeToast, null, this.failureToast);
            } else if (error.message == 'Verification code expired. Please resend verification code an try again') {
                this.codeForm.reset();
                this.toaster.open(this.codeExpiredToast, null, this.failureToast);
            } else if (error.message == 'User Locked') {
                this.codeForm.reset();
                this.toaster.open(this.accountLockedToast, null, this.unSuccessfulFailureToast);
            }
        });
    }

    private navigateToResetPassword(currentPassword): void {
        this.router.navigate([this.redirectService.urls.resetPassword], { state: { currentPassword } });
    }
}
