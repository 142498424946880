import { Component, OnInit } from '@angular/core';

/*
 * @author          @version    @date           @description
 * LMudalig         01          Dec 15, 2023    AFLL-19610 Sonar | VDS Portal | Removed unexpected empty constructor.
 */

@Component({
  selector: 'app-depuy-synthes-employee-privacy-policy',
  templateUrl: './depuy-synthes-employee-privacy-policy.component.html',
  styleUrls: ['./depuy-synthes-employee-privacy-policy.component.scss', '../footer-links-common.scss']
})
export class DepuySynthesEmployeePrivacyPolicyComponent implements OnInit {

  ngOnInit(): void {
  }

}
