<div class="hp-hcp-auth-app">
    <div class="signin-wizard">
        <div class="login-logo-row text-center">
            <img class="login-logo" src="../../../assets/img/velys-vds-logo.png" alt="velys Logo signin" />
        </div>
        <div class="lbl-title">
            <span translate>
                termsAndUse.setup_authentication
            </span>
        </div>
        <div class="label-clr" translate>
            termsAndUse.req_mfa
        </div>
        <div class="input-signin-but text-center btn-clr">
            <button mat-raised-button color="primary" class="login-button btn-blue-clr"
                (click)="mfaActivate()">
                {{ 'termsAndUse.continue' | translate }}
            </button>
        </div>
    </div>
</div>