<div class="hp-hcp-auth-app" *ngIf="isErrorMessageShown">
  <div class="signin-wizard">
    <div class="login-logo-row text-center">
      <img class="login-logo" src="../../../assets/img/velys-vds-logo.png" alt="velys Logo signin" />
    </div>

    <div class="signin-inactive-error signin-error-center-model text-center">
      <p>{{errorMessage | translate}}</p>
    </div>

    <div class="input-signin-but text-center">
      <button mat-raised-button color="primary" class="login-button" (click)="navigateToLogin()">
        Return to Login
      </button>
    </div>
  </div>
</div>
