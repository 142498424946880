import {App} from './app.model';
import { OrganizationModel } from './organization.model';

/*
 * @author          @version    @date           @description
 * HSenevir         01          Jan 26, 2024    AFLL-20257 - Enabled Okta session logout
 */
export interface UserModel {
  firstName: string;
  lastName: string;
  email: string;
  accessToken: string;
  refreshToken?: string;
  oktaFactorId?: string;
  stateToken?: string;
  status: UserStatus;
  passwordChanged: string;
  privacyPolicyAccepted: boolean;
  apps: App[];
  authorizationUrl: string;
  organization: OrganizationModel;
  logoutUrl: string;
}

export enum UserStatus {
  SUCCESS = 'SUCCESS',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  PASSWORD_WARN = 'PASSWORD_WARN',
  LOCKED_OUT = 'LOCKED_OUT',
  MFA_ENROLL = 'MFA_ENROLL',
  MFA_REQUIRED = 'MFA_REQUIRED'
}
