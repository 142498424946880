<footer [ngClass]="{'app-footer': isAuthenticated, 'app-footer-unsigned': !isAuthenticated}">
  <div class="footer-toolbar-container">
      <div class="footer-links col-lg-8 col-md-6 col-sm-12">
        <img src="../../../assets/img/DPS_Logo@2x.png" class="dps-logo-img" alt="Depuy Synthes">
        <button mat-button color="primary" class="footer-button" (click)="goToPrivacyPolicyScreen()">
          <span class="footer-text">Privacy Policy</span>
        </button>
        <button mat-button color="primary" class="footer-button" (click)="goToContactUsScreen()">
          <span class="footer-text">Contact Us</span>
        </button>
        <button mat-button color="primary" class="footer-button" *ngIf="country=='US'">
          <span class="footer-text">
            <a target="_blank"
              href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
              class="link">Do Not Sell or Share<br/> My Personal Information</a></span>
        </button>
      </div>

    <div class="footer-copyright col-lg-4 col-md-6  col-sm-12">
      <p class="footer-copyright-text">&copy; DePuy Synthes {{year}}. All rights reserved.<br/>This site is published
        by Medical Device Business Services, Inc.,<br/>which is solely responsible for its contents.</p>
    </div>
  </div>
</footer>
