<div class="privacy-container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <img src="../../../assets/img/velys-logo-v2.png" class="header-logo-img" alt="VELYS Insights"
           (click)="goToLogin()">
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="privacy-and-terms-form">
    <form [formGroup]="privacyAndTermsForm">
      <h3 class="privacy-title" translate>privacyAcceptance.privacyPolicy</h3>
      <div class="privacy-content" (scroll)="onScrolledToBottom($event)" *ngIf="country!=('UK' && 'ANZ')">
        <p class="updated-date">{{ 'privacyAcceptance.lastUpdated' | translate }}: March 02, 2023</p>
        <app-privacy-policy isUsedInAcceptance="true"></app-privacy-policy>
      </div>
      <div class="privacy-content" (scroll)="onScrolledToBottom($event)" *ngIf="country=='UK'">
        <p class="updated-date">{{ 'privacyAcceptance.lastUpdated' | translate }}: 02 March, 2023</p>
        <app-uk-privacy-policy isUsedInAcceptance="true"></app-uk-privacy-policy>
      </div>
      <div class="privacy-content" (scroll)="onScrolledToBottom($event)" *ngIf="country=='ANZ'">
        <p class="updated-date">{{ 'privacyAcceptance.lastUpdated' | translate }}: 21 February, 2024</p>
        <app-anz-privacy-policy isUsedInAcceptance="true"></app-anz-privacy-policy>
      </div>

      <div class="privacy-and-terms-selection">
        <mat-checkbox formControlName="accepted" [disabled]="!alreadyReachedBottom || isSubmitted" color="primary"
                      disableRipple
                      class="velys-checkbox">{{ 'privacyAcceptance.agreeDescription' | translate }}</mat-checkbox>
      </div>

      <div class="form-buttons">
        <button type="submit"
                class="button submit-button"
                data-dom-id="i_accept_submit"
                [disabled]="!privacyAndTermsForm.value.accepted || isSubmitted"
                (click)="submitForm()" translate>
                privacyAcceptance.accept
        </button>
      </div>

    </form>
  </div>
</div>
