<div class="vds-vds-forgot-password">
    <div class="forgot-password-wizard" *ngIf="!emailSent">
        <div class="logo-row text-center">
            <img class="logo" src="../../../assets/img/velys-vds-logo.png" alt="velys Logo sign in" />
        </div>
        <br/>
        <div class="title" *ngIf="!isExpiredPassword" translate>forgotPassword.title</div>
        <div class="title" *ngIf="isExpiredPassword" translate>forgotPassword.pwd_expired</div>
        <div class="summary">
            <div class="expired-summary" *ngIf="isExpiredPassword" translate>forgotPassword.exp_reason</div>
            {{'forgotPassword.password_msg' | translate }}
        </div>
        <form [formGroup]="forgotPasswordForm" class="forgot-password-form-group">
            <div class="input-field-row">
                <label for="email" translate>login.email</label>
                <input class="form-email-input"
                    type="email" formControlName="email" placeholder="" id="email" autocomplete="off" />
                <span class="error-message" *ngIf="isInValidEmail" translate>login.validation_message</span>
            </div>
          <!--Honeypot Field -->
          <div class="input-field-row additional-field">
            <label for="contact-number">Contact</label>
            <input class="form-email-input"
                   type="text" formControlName="contactNumber" placeholder="" id="contact-number"
                   autocomplete="off" />
          </div>
          <!--Honeypot Field -->
          <div class="input-field-row additional-field">
            <label for="address">Address</label>
            <input class="form-email-input"
                   type="text" formControlName="address" placeholder="" id="address" autocomplete="off" />
          </div>

            <div class="text-center">
                <button mat-raised-button color="primary" class="button" (click)="onSubmit()" [disabled]="isSubmitted" >
                   {{ 'forgotPassword.submit' | translate }}
                </button>
            </div>
            <div class="text-center">
                <a class="cancel-link" routerLink="/login" routerLinkActive="active" translate>
                    forgotPassword.cancel
                </a>
            </div>
        </form>
    </div>
    <div class="confirmation-page" *ngIf="emailSent">
        <div class="logo-row text-center">
          <img class="logo" src="../../../assets/img/velys-vds-logo.png" alt="velys Logo sign in" />
        </div>
        <div class="title" translate>
            forgotPassword.all_set
        </div>
        <div class="summary " translate>
          forgotPassword.reset_msg
        </div>
        <div class="text-center">
            <button mat-raised-button color="primary" class="button" (click)="goToLogin()">
                {{ 'forgotPassword.return_login' | translate }}
            </button>
        </div>
    </div>

    <app-expired-link-modal *ngIf="showExpiredLinkModal">
    </app-expired-link-modal>

</div>
