/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
/*
 *  @author     @version    @date           @description
 *  EElangav    01          Feb 14, 2024    AFLL-20711 ANZ | Localize Privacy Policy copy  for a HCP user 
 *  EElangav    02          Feb 15, 2024    AFLL-20711 Added URls for AU and NZ Org's
 */
@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  urls = {
    login: '/login',
    forgotPassword: '/forgotpassword',
    resetPassword: '/resetpassword',
    dashboard: '/dashboard',
    privacyAcceptance: '/accept-privacy',
    privacyPolicy: '/general/privacy-policy',
    cookiePolicies: '/general/cookie-policies',
    termsOfUse:  '/general/terms-of-use',
    contactUs: '/general/contact-us',
    profile: '/user/profile',
    changePassword: '/user/reset-password',
    privacyPolicyCountryBased: {
      'us': '/general/privacy-policy',
      'uk': '/general/uk-privacy-policy',
      'anz': '/general/anz-privacy-policy',
      'au': '/general/anz-privacy-policy',
      'nz': '/general/anz-privacy-policy'
    }
  };

  private location;

  constructor(private router: Router) {
    this.location = window.location;
  }

  public internalNavigate(url, queryParams?: object): void {
    this.router.navigateByUrl(this.urlWithParams(url, queryParams));
  }

  public navigate(url: string, queryParams?: object): void {
    this.location.href = this.urlWithParams(url, queryParams);
  }

  private urlWithParams(url: string, queryParams: object): string {
    if (!queryParams) {
      return url;
    }

    const queryString = Object.keys(queryParams)
      .map((parameter: string) => {
        const value = queryParams[parameter];
        return `${encodeURIComponent(parameter)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    return `${url}?${queryString}`;
  }
}
