<section class="large-doc">
  <h4 class="title-section">
    DePuy Synthes Employee Privacy Policy
  </h4>
  <div>
    <p>
      Welcome to the VELYS&trade; Patient Path – For HCPs and website <a
      href="https://www.velys.jnj">https://www.velys.jnj</a>. This site is owned by Medical Device Business Services,
      Inc.. This Privacy Policy is designed to tell you about our practices regarding collection, use, and disclosure of
      information that you may provide via this website.
    </p>

    <p>
      The personal data collected by this site is intended for use by physicians and healthcare professionals to
      securely communicate with their care teams and help improve efficiencies throughout the surgical experience.
      Personal data may also be used by the company for performing a contract, to comply with a legal obligation or for
      our legitimate business interests; providing the functionality of the Service and fulfilling your requests;
      Accomplishing our business purposes; analysis of Personal Information to provide personalized services.
      Appropriate privacy and security protections are in place to prevent disclosure of your personal data except to
      those employees and third parties with a business need for access to your information.
    </p>

    <p>
      Personal data collected through this system may be disclosed to other Johnson & Johnson Operating Companies,
      located in the United States or elsewhere, for the purposes described above. In addition, personal data may be
      disclosed to third parties, located in the United States and/or any other country, but only:
    </p>

    <ol class="list-style" type="i">
      <li>
        to contractors we use to support our business, and other vendors who provide business support and technical
        services. Such vendors are obligated to treat employee personal data in accordance with this Privacy Policy.
      </li>
      <li>
        where required by applicable laws, court orders, or government regulations, or pursuant to a valid request of a
        governmental agency.
      </li>
    </ol>

    <br>

    <p>
      In addition, we will make full use of data that is not in personally identifiable form.
    </p>

    <p class="sub-title">Cross-Border Transfer</p>
    <p>
      Your personal information may be stored and processed in any country where we have facilities or service
      providers, and by using this Site or by providing consent to us (where required by law), you agree to the transfer
      of information to countries outside of your country of residence, including to the United States, which may
      provide for different data protection rules than in your country.
    </p>

    <p class="sub-title">How to Contact Us</p>

    <p>If you have any questions about this Privacy Policy, please contact us as follows: </p>
    <p>Email us at <a href="mailto:info@velysinsights.com">info&#64;velysinsights.com</a></p>

    <p class="sub-title">Changes to This Privacy Policy </p>
    <p>If this Privacy Policy changes, the revised policy will be posted on this site. This Privacy Policy was last
      updated on
      <span><b>June 14, 2022</b></span>.
    </p>
  </div>

</section>
