import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-expired-link-modal',
  templateUrl: './expired-link-modal.component.html',
  styleUrls: ['./expired-link-modal.component.scss']
})
export class ExpiredLinkModalComponent {

  constructor(
    private dialogRef: MatDialogRef<ExpiredLinkModalComponent>
  ) {
    dialogRef.disableClose = true;
  }

  public closeLinkExpirationModal(): void {
    this.dialogRef.close(false);
  }
}
