<app-loading-spinner></app-loading-spinner>
<section class="app-auth" [ngClass]="{'velys-signin-bg':!isPrivacyPolicyAcceptancePage()}">
    <div class="auth-container">
        <div class="auth-forms" [ngClass]="{'auth-forms-terms-privacy': isPrivacyPolicyAcceptancePage()}">
            <router-outlet></router-outlet>
        </div>
        <div class="seperator-style hide-lang"></div>
        <div class="user-options-container drop-pos hide-lang">
            <button mat-button id="language-options" (click)="toggleLanguageMenu($event)">
              <span id="user-options" name="selectedLang">{{selectedLang}}</span>
              <mat-icon id="user-options">expand_more</mat-icon>
            </button>
            <mat-card *ngIf="showLanguageMenu" class="header-dropdown-options">
              <div data-dom-id="my_settings">
                <button class="header-menu-item" (click)="toggleLanguage('en-US')">en</button>
              </div>
              <mat-divider class="divider-style"></mat-divider>
               <button class="header-menu-item" (click)="toggleLanguage('de')">de</button>
            </mat-card>
        </div>
    </div>
  <app-footer class="tkr-footer-set"></app-footer>
</section>
