<div class="mfa-send-code-app">
    <div class="mfa-send-code-wizard">
        <div class="login-logo-row text-center">
            <img class="login-logo" src="../../../assets/img/velys-vds-logo.png" alt="velys Logo send code" />
        </div>
        <div class="label-clr">
            <h1 translate>
              sendCode.heading
            </h1>
        </div>
        <div class="label-clr copy-text" translate>
          sendCode.send_code_text
        </div>
        <div class="input-send-but text-center btn-clr">
            <button mat-raised-button color="primary" class="send-button btn-blue-clr" (click)="mfaSendCode()">
              <span translate>sendCode.send</span>
            </button>
        </div>
    </div>
  </div>