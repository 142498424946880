import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieUtilityServiceService {

  keys = {
    ds: 'velys-insights-mobile-app-login'
  };

  constructor(private cookieService: CookieService) {
  }

  setCookie(key: string, value, expiration = null): void {
    this.cookieService.set(key, value, expiration, '/', null, true);
  }

  getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  deleteCookie(key: string): void {
    this.cookieService.delete(key);
  }

  check(key: string): boolean {
    return this.cookieService.check(key);
  }

  deleteAllCookies(): void {
    this.cookieService.deleteAll('/');
  }
}
