<div class="hp-hcp-auth-app">
    <div class="signin-wizard">
        <div class="login-logo-row text-center">
            <img class="login-logo" src="../../../assets/img/velys-vds-logo.png" alt="velys Logo signin" />
        </div>
        <div class="labels heading" translate>
            verifyCodeComponent.verifyItsYou
        </div>
        <div class="labels" translate>
            verifyCodeComponent.enterDigits<br/>
            verifyCodeComponent.didntReceiveCode <a class="resend-link" (click)="reSendCode()" translate> verifyCodeComponent.resendCode </a>
        </div>
        <form [formGroup]="codeForm" (ngSubmit)="onSubmit()">
            <div class="verification-input">
                <input class="code-input" type="text" placeholder="" formControlName="codeInput1" maxlength="1" size="1" (keydown)="onKeyDown($event)" (keyup)="goToNextInput($event)" [ngModel] = 'input1'/>
                <input class="code-input" type="text" placeholder="" formControlName="codeInput2" maxlength="1" size="1" (keydown)="onKeyDown($event)" (keyup)="goToNextInput($event)" [ngModel] = 'input2'/>
                <input class="code-input" type="text" placeholder="" formControlName="codeInput3" maxlength="1" size="1" (keydown)="onKeyDown($event)" (keyup)="goToNextInput($event)" [ngModel] = 'input3'/>
                <input class="code-input" type="text" placeholder="" formControlName="codeInput4" maxlength="1" size="1" (keydown)="onKeyDown($event)" (keyup)="goToNextInput($event)" [ngModel] = 'input4'/>
                <input class="code-input" type="text" placeholder="" formControlName="codeInput5" maxlength="1" size="1" (keydown)="onKeyDown($event)" (keyup)="goToNextInput($event)" [ngModel] = 'input5'/>
                <input class="code-input" type="text" placeholder="" formControlName="codeInput6" maxlength="1" size="1" (keydown)="onKeyDown($event)" (keyup)="goToNextInput($event)" [ngModel] = 'input6'/>
            </div>
        <div class="code-submit text-center">
            <button mat-raised-button color="primary" class="submit-button" type="submit" [disabled]="!codeForm.valid"> {{"verifyCodeComponent.submit" | translate}} </button>
        </div>
    </form>
    </div>
</div>