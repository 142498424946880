import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthComponent} from './auth.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyAcceptanceComponent } from './privacy-acceptance/privacy-acceptance.component';
import { MFAComponent } from './MFA/MFA-component';
import { MfaSendVerificationCodesComponent } from './mfa-send-verification-codes/mfa-send-verification-codes.component';
import { VerificationCodeComponent } from './MFA/verification-code/verification-code.component';
import { AuthorizationComponent } from './authorization/authorization.component';

const routes: Routes = [
  { path: '',
    component: AuthComponent,
    data: { pageTitle: 'Login'},
    children: [
      { path: 'login', component: LoginComponent, data: { pageTitle: 'Login'} },
      { path: 'forgotpassword', component: ForgotPasswordComponent, data: { pageTitle: 'Forgot Password' } },
      { path: 'resetpassword', component: ResetPasswordComponent, data: { pageTitle: 'Reset Password' } },
      { path: 'accept-privacy', component: PrivacyAcceptanceComponent, data: { pageTitle: 'Accept Privacy Policy' } },
      { path: 'MFA-component', component: MFAComponent, data: { pageTitle: 'MFA Component' } },
      { path: 'authorization', component: AuthorizationComponent, data: { pageTitle: 'Authorization' } },
      { path: 'app-mfa-send-verification-codes', component: MfaSendVerificationCodesComponent,
        data: {pageTitle: 'Send Verification Code'} },
      { path: 'verification-code', component: VerificationCodeComponent, data: { pageTitle: 'Verify It\'s You' } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
