/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Component, OnInit } from '@angular/core';
import { CustomerSupportModel } from 'src/app/model/customer-support.model';
import { AppConstants } from '../../../config/constants';
import { TranslateService } from '@ngx-translate/core';
/*
 *  @author     @version    @date           @description
 *  EElangav    01          Feb 22, 2024    AFLL-20712 Added Localization of Customer Support contact details for ANZ
 */
@Component({
    selector: 'vds-contact-us',
    templateUrl: './contact-us.html',
    styleUrls: ['./contact-us.scss', '../footer-links-common.scss']
})

export class ContactUsComponent implements OnInit {
    public version = require('../../../../../package.json').version;
    public productCode = AppConstants.productCode;
    public customerSupport: CustomerSupportModel = AppConstants.customerSupport;
    public customerSupportEmail = AppConstants.customerSupportEmail;
    public customerSupportEmailLink = 'mailto:' + this.customerSupportEmail;
    public selectedLang = "";
    public country: string;
    constructor(
        public translateService: TranslateService,
      ) {
        this.selectedLang =  this.translateService.currentLang;
      }
    public ngOnInit() {
      this.country = AppConstants.regionByHostName;
    }
}
