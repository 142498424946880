import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './service/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    data: { pageTitle: 'Dashboard'},
    component: DashboardComponent
  },
  {
    path: 'general',
    loadChildren: () => import('./general/footer-links.module').then(m => m.FooterLinksModule),
  },
  { path: 'user',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./hcp-user/hcp-user.module').then(m => m.HcpUserModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
