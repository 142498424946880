<section class="large-doc">
    <h4 class="title-section" *ngIf="!isUsedInAcceptance">
        Privacy Policy
    </h4>
    <div>
        <p>
            <b>Johnson & Johnson Medical Pty Ltd </b>and<b> Johnson & Johnson (New Zealand) Limited </b>
            respects and cares your privacy and wants you to be familiar with how we collect, use, and disclose
            information.
            This Privacy Policy describes our practices in connection with information that we or our service providers
            collect
            through the website or application (hereinafter the <b>“Service”</b>) operated and controlled by us from
            which you are
            accessing this Privacy Policy. We encourage you to read the full Privacy Policy before using this Service or
            providing
            any personal information. By providing personal information to us or by using the Service, you acknowledge
            that you have
            read and understand this Privacy Policy.
        </p>

        <p class="sub-title">USE BY MINORS </p>
        <p>
            The Service is not directed to individuals under the age of 13, and we request that these individuals not
            provide personal
            information through the Service. If your child has submitted Personal Information and you would like to
            request that such
            Personal Information be removed, please contact us as explained below under Contacting Us.
        </p>

        <p class="sub-title">INFORMATION COLLECTION</p>
        <p>
            We may ask you to submit personal information in order for you to benefit from certain features (such as
            newsletter subscriptions, tips/pointers, or order processing) or to participate in a particular activity
            (such as sweepstakes or other promotions). You will be informed what information is required and what
            information
            is optional.
            <br /><br />
            We may combine the information you submit with other information we have collected from you, whether on- or
            offline,
            including, for example, your purchase history. We may also combine it with information we receive about you
            from other
            sources, such as other Johnson & Johnson Affiliates, publicly available information sources (including
            information from
            your publicly available social media profiles), and other third-party information providers.
            <br /><br />If you submit any personal information relating to another individual to us, you represent that
            you have
            the authority to do so and to permit us to use the information in accordance with this Privacy Policy.
        </p>

        <p class="sub-title ">SENSITIVE INFORMATION </p>
        <p>
            Unless we specifically request or invite it, we ask that you not send us, and you not disclose, any
            sensitive
            personal information (e.g., government identifiers, information related to racial or ethnic origin,
            political
            opinions, religion or philosophical beliefs, health, sex life or sexual orientation, criminal background, or
            trade union membership, or biometric or genetic data for the purpose of uniquely identifying an individual)
            on
            or through the Service or otherwise to us.

        </p>

        <p class="sub-title ">AUTOMATIC INFORMATION COLLECTION AND USE </p>
        <p>
            We and our service providers may automatically collect and use information in the following ways as you
            navigate around the Service:
        </p>

        <p>
            <strong>Through your browser:</strong>
            Certain information is collected by most browsers, such as your Media Access Control (MAC) address,
            computer type (Windows or Mac), screen resolution, operating system name and version, and Internet
            browser type and version. We may collect similar information, such as your device type and identifier,
            if you access the Service through a mobile device. We use this information to ensure that the Service
            functions properly.
        </p>
        <p>
            <strong>Using cookies:</strong>
            Cookies are pieces of information stored directly on the computer you are using. Cookies allow us to
            collect information such as browser type, time spent on the Service, pages visited, and language
            preferences.
            We and our service providers use the information for security purposes, to facilitate navigation,
            display
            information more effectively, and to personalize your experience while using the Service. We also use
            cookies
            to recognize your computer or device, which makes your use of the Service easier, such as to remember
            what is
            in your shopping cart. In addition, we use cookies to gather statistical information about Service usage
            in order
            to continually improve its design and functionality, understand how individuals use it, and to assist us
            with
            resolving questions regarding it. Cookies further allow us to select which of our advertisements or
            offers are most
            likely to appeal to you and display them while you are on the Service. We may also use cookies in online
            advertising
            to track consumer responses to our advertisements. <br />
        </p>
        <p>
            You can refuse to accept these cookies by following your browser’s instructions; however, if you do not
            accept them, you may experience some inconvenience in your use of the Service. You may also not receive
            advertising or other offers from us that are relevant to your interests and needs. To learn more about
            cookies, please visit <a target="_blank" href="http://www.allaboutcookies.org/" class="link"
                data-dom-id="http://www.allaboutcookies.org/" data-event-group="footer">allaboutcookies.org</a>.
        </p>
        <p>
            <strong>Using Flash cookies:</strong>
            Our use of Adobe Flash technology (including Flash Local Stored Objects (“Flash LSOs”)) allows us to,
            among other things, serve you with more tailored information, facilitate your ongoing access to and use
            of the Service, and collect and store information about your use of the Service. If you do not want
            Flash LSOs stored on your computer, you can adjust the settings of your Flash player to block Flash LSO
            storage using the tools contained in the 
            <a target="_blank"
                    href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    class="link"
                    data-dom-id="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    data-event-group="footer">
                    Website Storage Settings Panel</a>. You can also control Flash LSOs
            by going to the 
            <a target="_blank"
                    href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                    class="link"
                    data-dom-id="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                    data-event-group="footer">
                    Global Storage Settings Panel
                </a>
             and following the instructions. Please note that setting the
            Flash
            Player to restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of some
            Flash
            applications. <br />
        </p>
        <p>
            <strong>Using pixel tags, web beacons, clear GIFs, or other similar technologies:</strong>
            These may be used in connection with some Service pages and HTML formatted e mail messages to,
            among other things, track the actions of users and e mail recipients, measure the success of our
            marketing
            campaigns, and compile statistics about Service usage.
        </p>
        <p>
            <strong>Interest-based advertising:</strong>
            We may use third-party advertising companies to serve advertisements regarding goods and services that
            may
            be of interest to you when you access and use the Service and other online services, based on
            information
            relating to your access to and use of the Service and other online services on any of your devices. To
            do so,
            these companies may place or recognize a unique cookie on your browser (including through the use of
            pixel tags).
            They may also use these technologies, along with information they collect about your online use, to
            recognize you
            across the devices you use, such as a mobile phone and a laptop.<br /><br />
            If you would like more information about this practice, and to learn how to opt-out of it, in desktop
            and mobile browsers on the particular device on which you are accessing this Privacy Policy, 
            please visit <a target="_blank" href="http://optout.aboutads.info/#/" class="link"
            data-dom-id="http://optout.aboutads.info/#/" data-event-group="footer">
            http://optout.aboutads.info/#/
        </a> and <a target="_blank" href="http://optout.networkadvertising.org/#/" class="link"
        data-dom-id="http://optout.networkadvertising.org/#/" data-event-group="footer">
        http://optout.networkadvertising.org/#/</a>. You may download the AppChoices 
            app at 
            <a target="_blank" href="https://www.aboutads.info/appchoices" class="link"
                    data-dom-id="https://www.aboutads.info/appchoices" data-event-group="footer">
                    www.aboutads.info/appchoices
                </a>to opt-out in mobile apps.
        </p>
        <p>
            <strong>IP address:</strong>
            Your IP address is a number that is automatically assigned to your computer by your Internet Service
            Provider.
            An IP address is identified and logged automatically in our server log files whenever a user visits the
            Service,
            along with the time of the visit and the pages visited. Collecting IP addresses is standard practice and
            is done
            automatically by many online services. We use IP addresses for purposes such as calculating Service
            usage levels,
            diagnosing server problems, and administering the Service. We may also derive your approximate location
            from your
            IP address.
        </p>
        <p>
            <strong>Device Information:</strong>
            We may collect information about your mobile device, such as a unique device identifier, to understand
            how
            you use the Service.
        </p>
        <p class="sub-title">HOW WE USE AND DISCLOSE INFORMATION </p>

        <p>We use and disclose information you provide to us as described to you at the point of collection. Please see
            the section entitled “Choices and Access,” below, to learn how you may opt out of certain of our uses and
            disclosures.
            <br /><br />
            Where required by applicable law, we will obtain your consent to our use of your personal information at the
            point of
            information collection. We may also use information from or about you as necessary to perform a contract, to
            comply with
            a legal obligation (for example, due to our pharmacovigilance obligations), or for our legitimate business
            interests. We
            may also rely on other legal bases, specifically for:
        </p>
            <ul class="sub-title">
                <li class="list-color"><b>Providing the functionality of the Service and fulfilling your requests.</b></li>
            </ul>
        <div class="content-to-right">
            <ul class="list-style" style="list-style-type:circle">
                <li>
                    to provide the functionality of the Service to you and providing you with related customer service;
                </li>
                <li>
                    to respond to your inquiries and fulfill your requests, such as to send you documents you request or
                    e-mail alerts; </li>
                <li>
                    to send you important information regarding our relationship with you or regarding the Service,
                    changes to
                    our terms,
                    conditions, and policies and/or other administrative information.</li>  
            </ul>
        </div>
        <p>
            We will engage in these activities to manage our contractual relationship with you and/or to comply with a legal
            obligation.
        </p>
            <ul class="sub-title">
                <li class="list-color"><b>Accomplishing our business purposes.</b></li>
            </ul>
            <div class="content-to-right">
              <ul class="list-style" style="list-style-type: circle">
                <li>
                    for data analysis, for example, to improve the efficiency of the Service;
                </li>
                <li>
                    for audits, to verify that our internal processes function as intended and are compliant with legal,
                    regulatory, or contractual requirements;
                </li>
                <li>
                    for fraud and security monitoring purposes, for example, to detect and prevent cyberattacks or
                    attempts to commit identity theft;
                </li>
                <li>
                    for developing new products and services;
                </li>
                <li>
                    for enhancing, improving or modifying our website or products and services;
                </li>
                <li>
                    for identifying Service usage trends, for example, understanding which parts of our
                    Service are of most interest to users; and
                </li>
                <li>
                    for determining the effectiveness of our promotional campaigns, so that we can
                    adapt our campaigns to the needs and interests of our users.
                </li>
            </ul>
        </div>  
        <p>
            We will engage in these activities to manage our contractual relationship with you,
            to comply with a legal obligation, and/or because we have a legitimate interest.
        </p>
            <ul class="sub-title">
                <li class="list-color"><b>Analysis of Personal Information to provide personalized services.</b></li>
            </ul>
        <div class="content-to-right">
            <ul class="list-style" style="list-style-type:circle">
                <li>
                    to better understand you, so that we can personalize our interactions with you and
                    provide you with information and/or offers tailored to your interests; </li>
                <li>
                    to better understand your preferences so that we can deliver content via the
                    Service that we believe will be relevant and interesting to you. </li>
            </ul>
        </div>
        <p>
            We will provide personalized services either with your consent or because we have a legitimate interest.
        </p>
        <p>We also disclose information collected through the Service: </p>
        <div class="content-to-right">
            <ul class="list-style">
                <li class="section"> to our affiliates for the purposes described in this Privacy Policy. A list of our
                    affiliates is available at
                    <a target="_blank" href="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filing"
                        class="link"
                        data-dom-id="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings"
                        data-event-group="footer">
                        johnsonandjohnson sec filings
                    </a>
                    (click on the link for Form 10K, Exhibit 21, under “SEC Filings”). <b>Mohnson & Johnson Medical Pty
                        Ltd
                        (Australia) and
                        Johnson & Johnson (New Zealand) Limited (New Zealand)</b> is the party responsible for the
                    management of
                    the jointly-used Personal Information;
                </li>

                <li class="section">to our third-party partners with whom we offer a co-branded or
                    co-marketed promotion;
                </li>

                <li class="section">to our third-party service providers who provide services, such as website hosting
                    and moderating, mobile application hosting, data analysis, payment processing, order fulfillment,
                    infrastructure provision, IT services, customer service, e-mail and direct mail delivery services,
                    auditing, and other services, in order to enable them to provide services; and
                </li>

                <li class="section">as permitted by applicable law, to a third party in the event
                    of any reorganization, merger, sale, joint venture, assignment, transfer, or other
                    disposition of all or any portion of our business, assets, or stock (including in connection with
                    any bankruptcy or similar proceedings).
                </li>
            </ul>
        </div>
        <p>
            In addition, we may use and disclose your information as we believe to be necessary or
            appropriate: (a) to comply with legal process or applicable law which may include laws
            outside your country of residence; (b) as permitted by applicable law to respond to requests
            from public and government authorities, which may include authorities outside your country of residence;
            (c) to enforce our terms and conditions; and (d) to protect our rights, privacy, safety, or property,
            and/or that of our affiliates, you, or others. We may also use and disclose your information in other
            ways,
            after obtaining your consent to do so.</p>
        <p>
            We may use and disclose information we collect automatically as described above under
            “Automatic Information Collection and Use.”
            In addition, where allowed by applicable law, we may use and disclose information that is not in
            personally
            identifiable form for any purpose. If we combine information that is not in personally identifiable form
            with
            information that is identifiable (such as combining your name with your geographical location), we will
            treat
            the combined information as personal information as long as it is combined.
        </p>
        <p class="sub-title">CHOICES AND ACCESS </p>

        <p><b>Your choices regarding our use and disclosure of your personal information</b></p>

        <p>We give you choices regarding our use and disclosure of your personal information for marketing purposes.
            You may opt out from: </p>
            <ul>
                <li class="list-color"><b>Receiving marketing communications from us:</b>
                    If you no longer want to receive marketing communications from us on a going forward basis, you
                    may opt out of receiving them by contacting us by email at <a
                        href="mailto:cusord@its.jnj.com">cusord&#64;its.jnj.com</a>. In your request to us,
                    please provide your name, identify the form(s) of marketing communications that you no longer wish
                    to receive, and include the address(es) to which it/they are sent. For example, if you no longer wish to receive 
                    marketing e mails or direct mail from us, tell us that, and provide your name and e mail or postal address.
                </li>
            </ul>

            <ul>
                <li class="list-color"><b>Receiving reminders from us:</b>
                    If you no longer want to receive medical reminders from us on a going forward basis, you may opt out of 
                    receiving them by contacting us by email at <a href="mailto:cusord@its.jnj.com">cusord&#64;its.jnj.com</a>. In 
                    your response to us, please provide your name and the e mail address or phone number at which you receive reminders from us.</li>
            </ul>
            <ul>
                <li class="list-color"><b>Our sharing of your personal information with affiliates and third party
                        partners:
                    </b>
                    If you previously opted in to receiving marketing communications from our affiliates or third-party 
                    partners, you may opt out of our sharing of your personal information with those parties for their direct 
                    marketing purposes on a going forward basis by contacting us as described in the CONTACTING US section below. In your 
                    communication to us, please state that we should no longer share your personal information with our affiliates and/or 
                    third party partners for their marketing purposes, and include your name and e mail address.</li>
            </ul>
        <p>
            We will seek to comply with your request(s) as soon as reasonably practicable. Please note that if you
            opt-out as
            described above, we may not be able to directly remove your personal information from the databases of
            our affiliates with which we have already shared your information (i.e., as of the date that we implement your opt-out request). 
            However, we will make reasonable efforts to inform our affiliates of your request. Please also note that if you 
            opt-out of receiving marketing-related messages from us, we may still send you important transactional and
            administrative messages from which you cannot opt-out.
        </p>
        <p><b>How you can access, change, or delete your personal information</b></p>

        <p>If you would like to review, correct, update, restrict, or delete your personal information, or if you would
            like to
            request to receive an electronic copy of your personal information for purposes of transmitting it to
            another company
            (to the extent these rights are provided to you by applicable law), please contact us as described in the
            CONTACTING US
            section below. We will respond to your request as soon as reasonably practicable and no later than one month
            after
            receipt. If circumstances cause any delay in our response, you will be promptly notified and provided a date
            for our
            response.</p>

        <p class="sub-title">CROSS-BORDER TRANSFER</p>
        <p>Your personal information may be stored and processed in any country where we have facilities or service
            providers,
            and by using our Service or by providing consent to us (where required by law), your information may be
            transferred
            to countries outside of your country of residence, including to the United States, which may provide for
            different
            data protection rules than in your country of residence. Nonetheless, appropriate contractual and other
            measures are
            in place to protect personal information when it is transferred to our affiliates or third parties in other
            countries.</p>

        <p class="sub-title">SECURITY</p>
        <p>We seek to use reasonable organizational, technical, and administrative measures designed to protect personal
            information under our control. However, no data transmission over the Internet or data storage system
            can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no
            longer secure
            (for example, if you feel that the security of any account you have with us has been compromised), please
            immediately
            notify us in accordance with the "Contacting Us" section below. </p>

        <p class="sub-title">RETENTION PERIOD </p>
        <p>We will retain your personal information for as long as needed or permitted in light of the purpose(s) for
            which
            it was obtained. The criteria used to determine our retention periods include: (i) the length of time we
            have an
            ongoing relationship with you and provide the Service to you; (ii) whether there is a legal obligation to
            which we
            are subject; and (iii) whether retention is advisable in light of our legal position (such as in regard to
            applicable
            statutes of limitations, litigation, or regulatory investigations).</p>

        <p class="sub-title">THIRD PARTY SITES AND SERVICES </p>
        <p>This Service may contain links to sites of third parties. This Privacy Policy does not address, and we are
            not responsible for, the privacy,
            information, or practices of any third parties, including any third party operating any site or online
            service
            (including, without limitation, any application) that is available through this Service or to which this
            Service contains a link.
            The availability of, or inclusion of a link to, any such site or property on the Service does not imply
            endorsement of it by
            us or by our affiliates. </p>

        <p class="sub-title">CONTACTING US(Australia)</p>

        <p><b>Johnson & Johnson Medical Pty Ltd</b>, located at 1-5 Khartoum Road, Macquarie Park, NSW, 2113 is the
            company
            responsible for collection, use, and disclosure of personal information under this Privacy Policy. </p>
        <p>If you have any questions about this Privacy Policy, please contact us via email <a
                href="mailto:RA-JNJAU-PrivacyOffi@ITS.JNJ.com">RA-JNJAU-PrivacyOffi&#64;ITS.JNJ.com</a>, or please write to
            the following address: </p>

        <p class="contact-us"><b>Privacy ANZ Johnson & Johnson Medical, PO Box 134, North Ryde, NSW, 1670</b></p>

        <p class="sub-title">CONTACTING US(New Zealand)</p>

        <p>Johnson & Johnson (New Zealand) Limited, located at 507 Mount Wellington Hwy, Mount Wellington, Auckland
            1060, is the
            company responsible for collection, use, and disclosure of personal information under this Privacy Policy.
        </p>
        <p>If you have any questions about this Privacy Policy, please contact us via email
            <a href="mailto:RA-JNJAU-PrivacyOffi@ITS.JNJ.com">RA-JNJAU-PrivacyOffi&#64;ITS.JNJ.com</a>, or please write to
            the
            following address:
        </p>

        <p class="contact-us">Privacy ANZ Johnson & Johnson Medical, PO Box 134, North Ryde, NSW, 1670, Australia</p>

        <p class="sub-title">PRIVACY COMPLAINTS(Australia) </p>
        <p> If you believe we have either breached the terms of this Privacy Policy or otherwise not complied with the
            Australian Privacy
            Principles made under the Privacy Act 1988 (Cth) or other applicable Australian privacy laws, regulations or
            binding codes
            affecting how we collect and manage your personal information please write or email to us using the
            ‘<b>Contacting Us</b>’
            details above. In your letter or email please: </p>
        <ul>
            <li>Tell us the details of your complaint including relevant dates and third parties (if any);</li>
            <li>Provide copies of any relevant documents or correspondence; </li>
            <li>How you would prefer for us to contact you with our reply.</li>
        </ul>
        <p>We will:</p>
        <ul>
            <li>Acknowledge receipt of your complaint within 2-3 business days; </li>
            <li>Provide you with a written response to your complaint within 10-15 business days - depending
                upon the complexity of your complaint this could be at an earlier or later time.</li>
        </ul>
        <p>Please note guidance from the Office of the Australian Information Commissioner (OAIC) requires any complaint
            must first be
            made to the respondent organization. The law also allows 30 days for the respondent organization to reply
            before a complainant
            may make a complaint to the OAIC.</p>
        <p>You can obtain further general information about your privacy rights and privacy law from the OAIC by:</p>
        <ul>
            <li>calling their Privacy Hotlin on: 1300 363 992</li>
            <li>visiting their website at:
                <a target="_blank" href="https://oaic.gov.au/" class="link" data-dom-id="https://oaic.gov.au/"
                    data-event-group="footer">
                    oaic.gov.au
                </a>
            </li>
            <li>emailing to:<a href="mailto:enquiries@oaic.gov.au">enquiries&#64;oaic.gov.au</a></li>
            <li>writing to: Office of the Australian Information Commissioner, GPO Box 5218, Sydney NSW 2001</li>
        </ul>

        <p class="sub-title">PRIVACY COMPLAINTS(New Zealand) </p>
        <p> If you believe we have either breached the terms of this Privacy Policy or otherwise not complied
            with the New Zealand Privacy Principles made under the Privacy Act 1993 or other applicable New Zealand
            privacy laws, regulations or binding codes affecting how we collect and manage your personal information
            please write or email to us using the ‘Contacting Us’ details above. In your letter or email please:
        </p>
        <ul>
            <li>Tell us the details of your complaint including relevant dates and third parties (if any);</li>
            <li>Provide copies of any relevant documents or correspondence; </li>
            <li>How you would prefer for us to contact you with our reply.</li>
        </ul>
        <p>We will:</p>
        <ul>
            <li>Acknowledge receipt of your complaint within 2-3 business days; </li>
            <li>Provide you with a written response to your complaint within 10-15 business days - depending
                upon the complexity of your complaint this could be at an earlier or later time.</li>
        </ul>
        <p>Please note guidance from the Office of the Privacy Commissioner New Zealand (‘OPC NZ’) requires any
            complaint must first be made to the respondent organization. The law also allows 30 days for the respondent
            organization to reply before a complainant may make a complaint to the OPC NZ.</p>
        <p>You can obtain further general information about your privacy rights and privacy law from the OPC NZ by:</p>
        <ul>
            <li>calling their Privacy Hotline on: 0800 803 909</li>
            <li>visiting their website at:
                <a target="_blank" href="http://www.privacy.org.nz/" class="link"
                    data-dom-id="http://www.privacy.org.nz/" data-event-group="footer">
                    www.privacy.org.nz
                </a>
            </li>
            <li>writing to:
                <br>Office of the Privacy Commissioner,
                <br> PO Box 10 094,
                <br>The Terrace,
                <br> Wellington, 6143.
            </li>
        </ul>

        <p class="sub-title">UPDATES TO THIS PRIVACY POLICY </p>
        <p>We may change this Privacy Policy. Any changes to this Privacy Policy
            will become effective when we post the revised Privacy Policy on the Service.
            Your use of the Service following these changes means that you accept the revised Privacy Policy.
            We recommend that you regularly review the Privacy Policy when you visit the Service. This policy was last
            updated in
            <span><b>21 February, 2024</b></span>.
        </p>
        <p>118958-210727 (Australia)</p>
        <p>194261-211101 (New Zealand) </p>
    </div>
</section>