import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/*
 * @author          @version    @date           @description
 * JVeerasa         01          Mar 01, 2023    AFLL-16881 - VDS Portal | Upgrade Angular to v15 release
 * LMudalig         02          Dec 15, 2023    AFLL-19610 Sonar | VDS Portal | Removed unexpected empty constructor.
 */

const MINIMUM_PASSWORD_LENGTH = 10;
const regex = {
  email: /^(?!\.)(?!.*?\.\.)(?!.*\.$)[\w-.]+(.[\w-]+)@([\w-]+\.(.[\w-]+)?.[a-zA-Z]{2,6}|(\d{1,3}.){3}\d{1,3})(:\d{4})?$/,
  password: {
    lowercase: /(?=.*[a-z])/,
    numbers: /(?=.*[0-9])/,
    special: /(?=.*[_+\-.,!?@#$%^&*();\/|<>])/,
    uppercase: /(?=.*[A-Z])/
  }
};

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  public email(control: UntypedFormControl): any {
    return !control.value || control.value.match(regex.email) ? null : { badFormat: true };
  }

  public password(control: UntypedFormControl): any {
    const { lowercase, uppercase, numbers } = regex.password;
    if (!control.value || control.value.length < MINIMUM_PASSWORD_LENGTH) {
      return { badFormat: true};
    }
    return !control.value || control.value.match(lowercase) && control.value.match(uppercase) && control.value.match(numbers) ?
      null : { badFormat: true};
  }

  public passwordStrength(password): boolean {
    if (!password || password.length < MINIMUM_PASSWORD_LENGTH) {
      return false;
    }

    const { lowercase, uppercase, numbers } = regex.password;
    return password.match(lowercase) && password.match(uppercase) && password.match(numbers);
  }
}
